import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const paginationBar = css`
    margin-top: 1rem;
`

export const topActionsContainer = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .search_input {
        width: 20rem;
        margin-bottom: 1rem;

        i {
            font-size: 20px;
        }
    }
`

export const emptyCardContainer = css`
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: ${colors.gray2};
    font-weight: 600;
`

export const cardContainer = css`
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-bottom: 1rem;
    background-color: ${colors.white};
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

    > * {
        color: ${colors.gray7};
    }
    
    svg {
        font-size: 20px;
        min-width: 1rem;
        margin-right: 1rem;
    }

    .address__row {
        display: flex;
        background-color: white;
        border-radius: 8px;
        transition: all 0.2s ease;
        justify-content: space-between;
        padding: .7rem .8rem;
        color: ${colors.gray1};
    }

    .address__row:hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        transform: translateY(-2px);
    }
    
    .address__row .trash__button {
        cursor: pointer;
        transition: opacity .1s ease-in;
        width: 20px;
        height: 20px;

        &:hover {
            opacity: .8;
        }
    }

    .address__content {
        display: inline-block;
        align-items: center;
        font-size: 15px;
        line-height: 1.4;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 60vw;
        padding: 0 2px;

        > * {
            vertical-align: middle;
        }
    }

    .address__content .name {
        font-weight: 600;
    }

    .address__content :not(.name) {
        font-style: italic;
    }

    .address__row {
        border-bottom: 1px solid ${colors.gray8};
    }

    .address__row {
        display: flex;
        flex-direction: row;
    }
`

export const deletionDialog = css`
    margin-top: 10px;
    
    div:first-of-type {
        margin-top: 1rem;
    }
`

export const deletionDialogContainer = css`
    div:first-of-type {
        overflow: hidden;
    }
`
