/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState, useCallback, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { jsx } from '@emotion/core'
import { LoadingAnimation, useToast } from '@bonitour/components'

import { PriceModifierService } from 'services/PriceModifier/Service'
import { useActivity } from 'contexts/Activity'
import { UpdateActivityFeeForm } from 'containers/ActivityFeeForm/UpdateActivityFeeForm'
import { LIMBER_EXPERIENCE_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'
import { loadingContainerOverlay } from 'assets/styles/global'

export const PriceModifier = () => {
  const history = useHistory()
  const { feeId } = useParams()
  const { add: addToast } = useToast()
  const { id: activityId, activity: { type } } = useActivity()
  const [activityFee, setActivityFee] = useState({})
  const [loading, setLoading] = useState(true)

  const path = useMemo(() => {
    if (type === TRANSPORT_TYPE) {
      return '/transport'
    } else if (type === LIMBER_EXPERIENCE_TYPE) {
      return '/limber-experience'
    }
    return '/activity'
  }, [type])

  const returnToEditHref = useMemo(() => {
    return `${path}/${activityId}/dashboard`
  }, [activityId, path])

  const returnToEdit = useCallback(() =>
    history.push(returnToEditHref), [history, returnToEditHref]
  )

  useEffect(() => {
    setLoading(true)
    PriceModifierService.get(activityId).then((data) => {
      setActivityFee(data)
    }).catch(() => {
      returnToEdit()
      addToast('Tarifário não encontrado')
    }).finally(() => {
      setLoading(false)
    })
  }, [feeId, activityId, returnToEdit, addToast])

  const onSuccess = useCallback(async (data) => {
    try {
      setLoading(true)
      await PriceModifierService.create(data)
      returnToEdit()
      addToast('Tarifário atualizado com sucesso', 'success')
    } catch (error) {
      addToast(error)
    } finally {
      setLoading(false)
    }
  }, [returnToEdit, addToast])

  const onSuccessUpdate = useCallback(async (priceModifierId, data) => {
    try {
      setLoading(true)
      await PriceModifierService.update(priceModifierId, data)
      returnToEdit()
      addToast('Tarifário atualizado com sucesso', 'success')
    } catch (error) {
      addToast(error)
    } finally {
      setLoading(false)
    }
  }, [returnToEdit, addToast])

  if (loading) {
    return <LoadingAnimation css={loadingContainerOverlay}/>
  }

  return (
    <>
      <UpdateActivityFeeForm
        activityFee={activityFee}
        onSuccess={onSuccess}
        onBackClick={returnToEdit}
        onSuccessUpdate={onSuccessUpdate}
        loading={loading}
      />
    </>
  )
}
