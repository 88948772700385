/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FiExternalLink } from 'react-icons/fi'
import { FaMapPin } from 'react-icons/fa6'
import { SelectableTable, useToast } from '@bonitour/components'
import { MOBILE_BREAK_POINT, newPickUpAddressLink, tableContainer, tableHeaderContainer } from './ListServicePickUpAddresses.style'
import { useCallback } from 'react'
import { useMediaQuery } from '@bonitour/app-functions'
import { usePickUpAddresses } from 'hooks/domains/usePickUpAddresses'
import { useServicePickUpAddresses } from 'hooks/domains/useServicePickUpAddresses'

const NewPickUpAddressLink = () => {
  return (
    <a target='_blank' css={newPickUpAddressLink} href='/activities/pick-up-addresses'>
      Cadastrar local de embarque <FiExternalLink/>
    </a>
  )
}

const TableHeaderDesktop = () => (
  <div css={tableHeaderContainer} key='header'>
    <span>Selecionar todos</span>
    <NewPickUpAddressLink/>
  </div>
)

const TableHeaderMobile = () => {
  return (
    <div css={tableHeaderContainer} className='mobile' key='header'>
      <span className='select_all__label'>Selecione os locais de embarque</span>
      <NewPickUpAddressLink />
    </div>
  )
}

export const ListServicePickUpAddresses = ({
  serviceId = ''
}) => {
  const { add: addToast } = useToast()
  const {
    pickUpAddresses,
    pagination,
    isFetching,
    isLoading,
    onPaginate: paginatePickUpAddresses
  } = usePickUpAddresses(serviceId)

  const { linkedAddressesIds, onLinkPickUpAddresses: linkServiceAddresses } = useServicePickUpAddresses({ serviceId })

  const onSubmitSelectedIds = useCallback(async (selectedIds) => {
    if (!pickUpAddresses?.length) {
      return addToast('Cadastre um local de embarque', 'warning')
    }

    await linkServiceAddresses({
      serviceId,
      pickUpAddressIds: selectedIds
    })
  }, [addToast, linkServiceAddresses, pickUpAddresses, serviceId])

  const onPagination = useCallback(({ page = pagination.page, perPage = pagination.perPage }) => {
    if (perPage !== pagination.perPage) {
      return paginatePickUpAddresses(1, perPage)
    }

    paginatePickUpAddresses(page, perPage)
  }, [paginatePickUpAddresses, pagination.page, pagination.perPage])

  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAK_POINT}px)`)

  return (
    <>
      {isMobile ? <TableHeaderMobile /> : null}

      <SelectableTable
        isLoading={isLoading || isFetching}
        onSubmit={onSubmitSelectedIds}
        emptyMessage='Nenhum local de embarque disponível'
        labels={
          [
            <TableHeaderDesktop key='header' />
          ]
        }
        customCss={[tableContainer]}
        alreadyLinkedIds={linkedAddressesIds}
        showFloatingBar={false}
        data={
          pickUpAddresses?.map(({ id, name, street, supplement, district }) => {
            return {
              id,
              address: (
                <div className='address__content'>
                  <FaMapPin/>
                  <span className="name">{name}</span>
                  {street && <span className="street">, {street}</span>}
                  {supplement && <span className="supplement">, {supplement}</span>}
                  {district && <span className="district">, {district}</span>}
                </div>
              )
            }
          })
        }
        pagination={{
          onChange: onPagination,
          isEnabled: true,
          totalEntries: pagination.total,
          currentPage: pagination.page,
          totalPages: pagination.totalPages,
          perPage: pagination.perPage
        }}
      />

    </>
  )
}
