import { PriceModifierListFirstParser } from './Parser'
import { createUpdatePriceModifierModel } from './Model'
import { orbCore } from 'services/Orb/Service'

export const PriceModifierService = {
  get (serviceId) {
    return orbCore.priceModifierDomain.get(serviceId).then(PriceModifierListFirstParser)
  },

  create (data) {
    return orbCore.priceModifierDomain.create(createUpdatePriceModifierModel(data))
  },

  update (priceModifierId, data) {
    return orbCore.priceModifierDomain.update(priceModifierId, createUpdatePriceModifierModel(data))
  }
}
