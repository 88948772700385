/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { memo } from 'react'
import { jsx, css } from '@emotion/core'
import { marginBottom } from 'assets/styles/global'
import { InputWithSuffix, flexHorizontallyCentered, H3, HorizontalInputFormGroup } from '@bonitour/components'

const pageStyle = {
  marginBottom20: marginBottom(20),
  sameLineInput: css`
    margin-left: 10px;
  `,
  littleInput: css`
    width: 160px;
  `
}

export const UpdateFeeBasicForm = memo(({
  register,
  errors = {}
}) => {
  return (
    <>
      <H3 css={pageStyle.marginBottom20}>Modificador Base</H3>
      <div css={[flexHorizontallyCentered]}>
        <p>Porcentagem do tarifário padrão</p>
        <HorizontalInputFormGroup css={pageStyle.sameLineInput} errorMessage={errors?.message}>
          <InputWithSuffix
            css={pageStyle.littleInput}
            type="number"
            min={100}
            {...register('description.defaultValue')}
            placeholder={100}
            onChangeWholeEvent
          >
            <span>%</span>
          </InputWithSuffix>
        </HorizontalInputFormGroup>
      </div>
    </>
  )
})
