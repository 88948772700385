/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { jsx, css } from '@emotion/core'
import { Card, colors, ConfirmDialog } from '@bonitour/components'
import { yupResolver } from '@hookform/resolvers/yup'
import { identity } from '@bonitour/common-functions'

import { useActivity } from 'contexts/Activity.js'
import { FormHeader } from 'components/FormHeader'

import { updateFeeSchema } from './UpdateActivityFeeForm.schema'
import { ActivitySelector } from 'containers/Activity/Selector/Selector'
import { marginBottom } from 'assets/styles/global'
import { UpdateFeeBasicForm } from './UpdateFeeBasic/UpdateFeeBasicForm'
import { UpdateFeeType } from './UpdateFeeBasic/UpdateFeeType'

const container = css`
  color: ${colors.gray3};
`

const pageStyle = {
  activitySelector: css`
    margin-bottom: 35px;
  `,
  span: css`
    margin-bottom: 5px;
    display: block;
  `
}

export const UpdateActivityFeeForm = ({
  onBackClick: emitBackClickEvent = identity,
  onSuccess: emitSuccessEvent = identity,
  onSuccessUpdate: emitSuccessUpdateEvent = identity,
  activityFee = {},
  loading
}) => {
  const [isConfirmActionVisible, setIsConfirmActionVisible] = useState(false)
  const { activity: { companyName }, id: activityId } = useActivity()

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(updateFeeSchema()),
    defaultValues: {
      id: activityFee?.id,
      serviceId: activityId,
      active: true,
      description: {
        defaultValue: activityFee?.description?.defaultValue || 100,
        valueByFee: {}
      }
    }
  })

  const formValidate = useCallback(data => {
    if (!data) {
      return
    }

    data.id = activityFee?.id
    data.serviceId = activityId

    if (data.id) {
      emitSuccessUpdateEvent(data.id, data)
      return
    }

    emitSuccessEvent(data)
  }, [activityFee?.id, activityId, emitSuccessEvent, emitSuccessUpdateEvent])

  const onDialoVisibilityChange = useCallback((value) => {
    setIsConfirmActionVisible(value)
  }, [])

  const clearClickEvent = useCallback(() => {
    if (activityFee?.id) {
      const data = {
        serviceId: activityFee?.serviceId,
        active: activityFee?.active,
        companyId: activityFee?.companyId,
        description: {
          defaultValue: 100,
          valueByFee: {}
        }
      }
      emitSuccessUpdateEvent(activityFee?.id, data)
      return
    }

    reset()
    onDialoVisibilityChange(false)
    emitBackClickEvent()
  }, [
    activityFee?.id,
    activityFee?.serviceId,
    activityFee?.active,
    activityFee?.companyId,
    reset,
    onDialoVisibilityChange,
    emitBackClickEvent,
    emitSuccessUpdateEvent
  ])

  const defaultValue = watch('description.defaultValue')
  const title = 'Modificador de Tarifário'

  return (
    <>
      <div css={container}>
        <ConfirmDialog
          title='Limpar Modificações?'
          message={'Você deseja remover as modificações de tarifário? O tarifário do serviço voltará a ser o mesmo do fornecedor.'}
          isVisible={isConfirmActionVisible}
          successCallback={clearClickEvent}
          cancelCallback={() => onDialoVisibilityChange(false)}
        />
        <FormHeader
          title={title}
          disabled={loading}
          css={[marginBottom(0)]}
          ghostClick={emitBackClickEvent}
          ctaClick={handleSubmit(formValidate)}
          clearClick={() => onDialoVisibilityChange(true)}
        />
        <ActivitySelector css={pageStyle.activitySelector} subdomain="price-modifier" disableOffline />
        <span css={pageStyle.span}>
          Modificações de tarifário serão aplicadas para vendas desse serviço realizadas pela empresa atual ({companyName}).
        </span>
        <span css={[pageStyle.span, marginBottom(30)]}>
        Essas mudanças não serão aplicadas caso a venda seja efetuada por outra empresa.
        </span>

        <Card css={container}>
          <UpdateFeeBasicForm
            errors={errors?.description?.defaultValue}
            register={register}
            setValue={setValue}
            activityFee={activityFee}
          />
          <UpdateFeeType
            errors={errors?.description}
            register={register}
            defaultValue={defaultValue}
            setValue={setValue}
            trigger={trigger}
            activityFee={activityFee}
          />
        </Card>

      </div>
    </>
  )
}
