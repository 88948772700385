
export function PriceModifierListFirstParser (PriceModifierList = []) {
  return PriceModifierList?.price_modifiers
    .map((item) => {
      return {
        id: item.id,
        serviceId: item.service_id,
        companyId: item.company_id,
        active: item.active,
        description: {
          defaultValue: item?.description?.default_value ? parseFloat((item.description.default_value * 100).toFixed(2)) : 100,
          valueByFee: item?.description?.value_by_fee
            ? Object.keys(item?.description?.value_by_fee).reduce((acc, key) => {
              if (item.description.value_by_fee[key]) {
                acc[key] = parseFloat((item.description.value_by_fee[key] * 100).toFixed(2))
              }
              return acc
            }, {})
            : {}
        }
      }
    })
    .find((item) => item)
}
