import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const MOBILE_BREAK_POINT = 900

export const tableContainer = css`
  color: ${colors.gray3};

  .TableCell {
    div:first-of-type {
      gap: 0;
    }
  }

  .checkbox_header__container {
    width: 100%;
    display: flex;
    gap: 0;
    overflow: hidden;
  }

  .address__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    width: 100%;

    svg {
      font-size: 16px;
    }
  }

  .address__content .name {
    font-weight: 700;
    margin-left: 5px;
  }

  .address__content :not(.name) {
      font-style: italic;
  }

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    li {
      margin: 0;
    }

    .checkbox_header__container {
      display: none;
    }

    .TableCellWrapper {
      grid-template-columns: 1fr;
    }

    .TableCellRef {
      display: none;
      background-color: ${colors.white};
    }
  }
`

export const tableHeaderContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 1rem;
  width: 100%;
  color: ${colors.gray1};

  .select_all__label {
    font-weight: bold;
  }

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    &.mobile:not() {
      display: none;
    }
  }

  @media (max-width: ${BREAK_POINTS.smallPhone}) {
    flex-direction: column;
    gap: 1rem;
  }
`

export const newPickUpAddressLink = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  color: ${colors.gray3};

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    margin-bottom: 10px;
  }
`
