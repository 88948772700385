import { css } from '@emotion/core'
import { BREAK_POINTS, colors } from '@bonitour/components'

export const highlightLabel = css`
  font-weight: 700;
`

export const pickUpAddressContainer = css`
background-color: ${colors.gray13};
margin-top: -10px;
border-radius: 0 0 10px 10px;
padding-top: 0.25rem;
`

export const mapCardBackground = css`
  background-color: transparent;
  border: none;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    padding-left: 0;
    margin-bottom: 20px;
  }
`

export const formGrid = css`
  display: grid;
  grid-template-columns: 5fr 2fr 3fr 3fr 5fr;
  gap: 20px;
  @media (max-width: ${BREAK_POINTS.desktop}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: ${BREAK_POINTS.tablet}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    grid-template-columns: 1fr;
  }
`

export const formItem = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`
