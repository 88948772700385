/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card, Label, colors, Select, BREAK_POINTS, WarningIcon } from '@bonitour/components'
import { useMemo, useCallback, useEffect } from 'react'
import { hidden } from 'assets/styles/global'

const highlightLabel = css`
  font-weight: 700;
`

const limberCard = css`
  background-color: ${colors.gray13};
  margin-top: -10px;
  border-radius: 0 0 10px 10px;
  padding-top: 0.25rem;
`

const combinedCardStyle = css`
  background-color: ${colors.gray10};
  padding-top: 10px;
  margin-top: -10px;
  label {
    font-weight: 500;
    font-size: 0.75rem;
  }
  > div {
    margin-left: -10px;
    margin-right: -10px;
    > div > div {
      min-height: 28px;
      padding: 3px 40px 3px 10px;
      margin: 2px 0 4px;
    }
  }
  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    margin-left: 1rem;
  }
`

const mapCardBackground = css`
  background-color: transparent;
  border: none;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    padding-left: 0;
  }
`

const formGrid = css`
  display: grid;
  grid-template-columns: 5fr 2fr 3fr 3fr 5fr;
  gap: 20px;

  @media (max-width: ${BREAK_POINTS.desktop}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    grid-template-columns: 1fr;
  }
`

const formItem = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const noPickupWarning = css`
  font-size: 0.75rem;
  padding: 0.25rem;
  display: flex;
  gap: 0.25rem;
  font-weight: 600;
  color: ${colors.orange1};

  i {
    font-size: 1rem;
  }
`

const DEFAULT_PICKUP_PLACE_INDEX = 0

export const LimberSection = ({
  disabled = false,
  onChange,
  onBlur,
  limberData = {},
  pickupPlaceId,
  bookingMapStyle = false,
  isCombinedExperience = false,
  pickupOptions = [],
  ...other
}) => {
  const handlePickupPlaceChange = useCallback((chosenPickUpPlaceid) => {
    onChange(chosenPickUpPlaceid)
  }, [onChange])

  const pickupPlaceIdsOptions = useMemo(
    () => limberData?.locaisEmbarque
      ?.filter(({ localEmbarque }) =>
        !isCombinedExperience || pickupOptions.some(({ pickupId }) => (pickupId === String(localEmbarque)))
      )
      ?.map((localEmbarque) => (
        {
          label: localEmbarque.nomeLocalEmbarque,
          value: String(localEmbarque.localEmbarque)
        }
      ))
      ?.sort((a, b) => a.label.localeCompare(b.label)),
    [limberData, pickupOptions, isCombinedExperience]
  )

  const displayNoPickupWarning = useMemo(
    () => limberData?.locaisEmbarque?.length > 0 && pickupPlaceIdsOptions?.length <= 0,
    [limberData, pickupPlaceIdsOptions]
  )

  const isHidden = useMemo(
    () => (disabled || (pickupPlaceIdsOptions?.length <= 0 && !displayNoPickupWarning)),
    [pickupPlaceIdsOptions, disabled, displayNoPickupWarning]
  )

  useEffect(() => {
    if ((!pickupPlaceId || pickupPlaceId === 0) && pickupPlaceIdsOptions?.length > 0) {
      handlePickupPlaceChange(
        pickupPlaceIdsOptions[Math.min(DEFAULT_PICKUP_PLACE_INDEX, pickupPlaceIdsOptions.length - 1)].value
      )
    }
  }, [pickupPlaceId, pickupPlaceIdsOptions, handlePickupPlaceChange])

  return (
    <Card padding='20' css={[limberCard, isHidden && hidden, bookingMapStyle && mapCardBackground, isCombinedExperience && combinedCardStyle]} {...other}>
      <div css={[!bookingMapStyle && formGrid]}>
        <div css={[!bookingMapStyle && formItem]}>
          <Label htmlFor='pickupPlaceId' css={highlightLabel}>Local de Embarque</Label>
          {displayNoPickupWarning
            ? (
              <p css={noPickupWarning}>
                <WarningIcon />
              Nenhum local de embarque ativo nesta exp. combinada!
              </p>
            )
            : (
              <Select
                placeholder='Selecione Local de Embarque'
                onChange={handlePickupPlaceChange}
                onBlur={onBlur}
                error={false}
                disabled={isHidden}
                value={pickupPlaceId}
                options={pickupPlaceIdsOptions || []}
              />
            )}
        </div>
      </div>
    </Card>
  )
}
