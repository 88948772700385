/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, Label, Select } from '@bonitour/components'
import { useMemo, useCallback } from 'react'
import { hidden } from 'assets/styles/global'
import { useServicePickUpAddresses } from 'hooks/domains/useServicePickUpAddresses'
import { formGrid, formItem, highlightLabel, pickUpAddressContainer as container, mapCardBackground } from './PickUpAddressSection.style'
import { identity } from '@bonitour/common-functions'
import { useFeatureFlag } from 'contexts/Feature'

export const PickUpAddressSection = ({
  onChange = identity,
  onBlur = identity,
  selectedPickUpAddressId,
  serviceId,
  disabled = false,
  bookingMapStyle = false,
  ...other
}) => {
  const handlePickupPlaceChange = useCallback((pickUpPlaceId) => {
    onChange(pickUpPlaceId)
  }, [onChange])

  const { linkedAddresses } = useServicePickUpAddresses({ serviceId })

  const pickUpAddressOptions = useMemo(() =>
    linkedAddresses?.map(({ id, name }) => (
      {
        label: name, value: id
      }
    )), [linkedAddresses])

  const isHidden = useMemo(() => (disabled || !(pickUpAddressOptions?.length > 0)), [pickUpAddressOptions, disabled])

  const [isPickupPlacesEnabled, _, isFlagReady] = useFeatureFlag('orb-transport-pickup-selector')

  if (!isFlagReady || !isPickupPlacesEnabled) {
    return null
  }

  return (
    <Card padding='20' css={[container, isHidden && hidden, bookingMapStyle && mapCardBackground]} {...other}>
      <div css={[!bookingMapStyle && formGrid]}>
        <div css={[!bookingMapStyle && formItem]}>
          <Label htmlFor='pickupPlaceId' css={highlightLabel}>Local de Embarque</Label>
          <Select
            placeholder='Selecione Local de Embarque'
            onChange={handlePickupPlaceChange}
            onBlur={onBlur}
            error={false}
            disabled={isHidden}
            value={selectedPickUpAddressId}
            options={pickUpAddressOptions || []}
          />
        </div>
      </div>

    </Card>
  )
}
