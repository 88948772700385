/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { Column, H3, Input, InputFormGroup, InputWithPrefix, InputWithSuffix, Row, colors } from '@bonitour/components'
import { css, jsx } from '@emotion/core'
import { useHookFormMask } from 'use-mask-input'
import { FieldLabel } from 'components/MultiLanguageInput/MultiLanguageInput'
import { CombinedExperienceFormFeeMap } from './CombinedExperienceFormFeeMap'

const priceMask = ['0,00', '0,09', '0,99', '9,99', '99,99', '999,99', '9999,99', '99999,99']

const warningStyle = css`
  color: ${colors.orange3};
`

export const CombinedExperienceFormEcommerce = ({
  register,
  errors,
  isLoading,
  control,
  selectedServices,
  setValue,
  watch
}) => {
  const registerWithMask = useHookFormMask(register)

  const [startingPrice, duration] = watch(['startingPrice', 'duration'])

  if (!selectedServices.length) {
    return null
  }

  return (
    <>
      <H3>Configurações para o E-commerce</H3>

      <Row>
        <Column phone={10} desktop={3}>
          <InputFormGroup
            label={
              <FieldLabel
                label='Preço base (a partir de)'
                customCss={[!startingPrice && warningStyle]}
                infoMessage={
                  <>
                    Preço que será exibido na listagem de experiências no Xpert(e-commerce). <br />
                    Preço apenas para exibição, o preço que o usuário pagará será calculado com base no tarifário do serviço após seleção de data e local de embarque.
                  </>
                }
              />
            }
            errorMessage={errors?.startingPrice?.message}
          >
            <InputWithPrefix
              disabled={isLoading}
              onChangeWholeEvent
              {...registerWithMask('startingPrice', priceMask)}
            >
              R$
            </InputWithPrefix>
          </InputFormGroup>
        </Column>

        <Column phone={10} desktop={3}>
          <InputFormGroup
            label={<FieldLabel label='Tempo de duração' customCss={[!duration && warningStyle]} />}
            errorMessage={errors?.duration?.message}
          >
            <InputWithSuffix
              disabled={isLoading}
              onChangeWholeEvent
              type='number'
              min={0}
              {...register('duration')}
            >
              minutos
            </InputWithSuffix>
          </InputFormGroup>
        </Column>
        <Column phone={10} desktop={3}>
          <InputFormGroup
            label={
              <FieldLabel
                label='Ordem (decrescente)'
                infoMessage={
                  <>
                    Altera a posição em que a experiência será exibida no Xpert(e-commerce). <br />
                    Quanto maior o valor, mais a frente o serviço será apresentado na listagem.  <br />
                    Deixando vazio a experiência será exibida no final da listagem.
                  </>
                }
              />
            }
            errorMessage={errors?.order?.message}
          >
            <Input
              disabled={isLoading}
              onChangeWholeEvent
              type='number'
              min={0}
              max={999999999}
              step={1}
              {...register('order')}
            />
          </InputFormGroup>
        </Column>
      </Row>
      <Row>
        <Column phone={12} desktop={12}>
          <CombinedExperienceFormFeeMap
            control={control}
            register={register}
            errors={errors}
            isLoading={isLoading}
            selectedServices={selectedServices}
            setValue={setValue}
            watch={watch}
          />
        </Column>
      </Row>
    </>
  )
}
