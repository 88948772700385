
export const ServicePickUpAddressParser = {
  linkedAddresses: (data) => {
    const { service_pick_up_addresses: servicePickUpAddresses = [] } = data

    return servicePickUpAddresses.map(address => ({
      id: address.pick_up_address.id,
      name: address.pick_up_address.name,
      company_id: address.pick_up_address.company_id,
      cityId: address.pick_up_address.city_id,
      stateId: address.pick_up_address.state_idd,
      countryId: address.pick_up_address.country_id,
      postCode: address.pick_up_address.post_code,
      street: address.pick_up_address.street,
      supplement: address.pick_up_address.supplement,
      district: address.pick_up_address.district,
      latitude: address.pick_up_address.latitude,
      longitude: address.pick_up_address.longitude
    }))
  }

}
