/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { InfoSwatch, Input, InputFormGroup, Label, NavContainer, NavItem, TabContainer, TabPane, Textarea } from '@bonitour/components'
import { errorTabStyle, fieldLabelStyle, hide, infoTooltipStyle, inputGroupStyle, inputStyle, tabContainerStyle, tabPaneStyle, tabStyle, textareaStyle, warningTabStyle } from './MultiLanguageInput.styles'
import { useCallback, useMemo, useState } from 'react'
import { LANG_LABELS } from 'constants/languages'

export const FieldLabel = ({ label = '', infoMessage = '', isRequired = false }) => (
  <Label css={fieldLabelStyle}>
    {label}
    <p>
      ({isRequired ? 'Obrigatório' : 'Opcional'})

      <InfoSwatch customTooltip={[!infoMessage && hide]} css={[infoTooltipStyle, !infoMessage && hide]} size={300} tooltip={infoMessage} />
    </p>

  </Label>
)

export const LANG_KEYS = Object.keys(LANG_LABELS)

export const MultiLanguageInput = ({
  label,
  isRequired = false,
  register = identity,
  errors = {},
  isLoading = false,
  name,
  customInputStyle = [],
  textArea = false,
  watch = identity,
  disableEmptyWarning = false
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(LANG_KEYS[0])

  const descriptionLangWithErrors = useMemo(
    () => Object.keys(errors || {}),
    [errors]
  )
  const firstDescriptionError = useMemo(
    () => {
      const msg = Object.values(errors || {})?.[0]?.message
      const keysWithSameError = Object.keys(errors || {}).filter((key) => errors[key]?.message === msg)
      return msg ? `${msg} (${keysWithSameError.map((lang) => LANG_LABELS[lang]).join(', ')})` : msg
    },
    [errors]
  )

  const errorStyle = useCallback(
    (lang) => descriptionLangWithErrors.includes(lang) ? errorTabStyle : null,
    [descriptionLangWithErrors]
  )

  const InputOrTextArea = useMemo(() => textArea ? Textarea : Input, [textArea])
  const inputOrTextAreaStyle = useMemo(() => textArea ? textareaStyle : inputStyle, [textArea])

  const values = watch(LANG_KEYS.map((lang) => `${name}.${lang}`))
  const emptyLangs = useMemo(() => LANG_KEYS.filter((_, index) => !values?.[index]), [values])

  const emptyWarningStyle = useCallback(
    (lang) => (!disableEmptyWarning && emptyLangs.includes(lang)) ? warningTabStyle : null,
    [emptyLangs, disableEmptyWarning]
  )

  return (
    <InputFormGroup label={<FieldLabel label={label} isRequired={isRequired} />} errorMessage={firstDescriptionError} customCss={[inputGroupStyle]}>
      <div>
        <NavContainer selectedNav={selectedLanguage} onChange={setSelectedLanguage} customCss={[tabContainerStyle]}>
          {LANG_KEYS.map((lang) => (
            <NavItem navId={lang} key={lang} customCss={[tabStyle, emptyWarningStyle(lang), errorStyle(lang)]}>
              {LANG_LABELS[lang]}
            </NavItem>
          ))}
        </NavContainer>
        <TabContainer activeTab={selectedLanguage}>
          {LANG_KEYS.map((lang) => (
            <TabPane tabId={lang} key={lang} customCss={[tabPaneStyle]}>
              <InputOrTextArea
                error={errors?.[lang]}
                css={[inputOrTextAreaStyle, ...customInputStyle]}
                onChangeWholeEvent
                disabled={isLoading}
                {...register(`${name}.${lang}`)}
              />
            </TabPane>
          ))}
        </TabContainer>
      </div>
    </InputFormGroup>
  )
}
