
import { useDebounce } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { useToast } from '@bonitour/components'
import { PickUpAddressesService } from 'core/services/PickUpAddress'
import { useState } from 'react'
import { useQuery } from 'react-query'

export const usePickUpAddresses = (serviceId = '') => {
  const [name, setName] = useState()
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    total: undefined,
    totalPages: undefined
  })

  const { add: addToast } = useToast()

  const nameWithDebounce = useDebounce(name, 1000) // 1 second

  const {
    data,
    isLoading,
    isFetching,
    refetch
  } = useQuery(
    `
      pickUpAddresses
      name: ${nameWithDebounce}
      service: ${serviceId}
      page: ${pagination.page}
      perPage: ${pagination.perPage}
    `
    ,
    async () => await PickUpAddressesService.list({
      name: nameWithDebounce,
      serviceId,
      pagination
    }).then(({ pickUpAddresses, meta }) => {
      const newPagination = {
        perPage: pagination.perPage,
        page: meta.currentPage,
        total: meta.totalEntries,
        totalPages: meta.totalPages
      }

      setPagination(newPagination)

      return {
        pickUpAddresses,
        pagination: newPagination
      }
    }),
    {
      enabled: true,
      refetchOnWindowFocus: false
    }
  )

  const onPaginate = (page = pagination.page, perPage = pagination.perPage) => {
    setPagination(currPag => ({
      page,
      perPage,
      total: currPag.total,
      totalPages: currPag.totalPages
    }))
  }

  const onDelete = async ({ id, onSuccess = identity, onError = identity }) => {
    await PickUpAddressesService.delete(id).then(() => {
      onSuccess()
      addToast('Local de embarque removido com sucesso', 'success')
    }).catch(() => {
      onError()
      addToast('Erro ao remover local de embarque')
    })
  }

  const onCreate = async ({ data, onSuccess = identity, onError = identity }) => {
    return await PickUpAddressesService.create(data).then(() => {
      onSuccess()
      addToast('Local de embarque criado com sucesso', 'success')
    }).catch(() => {
      onError()
      addToast('Erro ao adicionar local de embarque')
    })
  }

  return {
    onCreate,
    onDelete,
    onRefetch: refetch,
    onPaginate,
    onChangeName: setName,
    isLoading,
    isFetching,
    pagination: data?.pagination || pagination,
    pickUpAddresses: data?.pickUpAddresses || []
  }
}
