/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useMemo } from 'react'
import { jsx, css } from '@emotion/core'
import { flex, BREAK_POINTS } from '@bonitour/components'
import { UpdateFeeTypeRow } from './UpdateFeeTypeRow'
import { FEE_TYPES } from 'constants/fees'
import { useActivity } from 'contexts/Activity'
import { SafeJSONParse } from 'utils/object'
import { LIMBER_EXPERIENCE_TYPE } from 'constants/activityTypes'

const feeTypeRowContainer = css`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${BREAK_POINTS.tableUp}) {
    justify-content: center;
  }
`

export const UpdateFeeType = ({
  errors = {},
  register,
  defaultValue,
  setValue,
  trigger,
  activityFee = {}
}) => {
  const { activity } = useActivity()
  const isLimber = useMemo(
    () => activity.type === LIMBER_EXPERIENCE_TYPE, [activity.type]
  )

  const mountLimberList = useMemo(() => {
    if (!activity) {
      return []
    }

    const limberData = SafeJSONParse(activity?.description)?.limber_data
    if (!limberData) {
      return []
    }

    return limberData.categorias.map(({ nomeCategoria }) => ({
      name: nomeCategoria,
      feeName: nomeCategoria
    }))
  }, [activity])

  const defaultList = useMemo(
    () => Object.values(FEE_TYPES).map(
      (name) => ({
        name,
        feeName: name
      })
    ), []
  )

  const feeList = useMemo(() => {
    return isLimber ? mountLimberList : defaultList
  }, [defaultList, isLimber, mountLimberList])

  return (

    <div css={[flex, feeTypeRowContainer]}>
      {
        feeList.map(({ name, feeName }) => (
          <UpdateFeeTypeRow
            key={name}
            feeName={feeName}
            errors={errors}
            indexName={name}
            register={register}
            defaultValue={defaultValue}
            setValue={setValue}
            trigger={trigger}
            activityFee={activityFee}
          />
        ))
      }
    </div>
  )
}
