import { ServicePickUpAddressParser } from 'core/parsers/servicePickUpAddress'
import { orbCore } from 'services/Orb/Service'

const { servicePickUpAddressDomain } = orbCore

export const ServicePickUpAddressesService = {
  async linkedAddresses ({ serviceId }) {
    return await servicePickUpAddressDomain.linkedAddresses({ serviceId }).then(ServicePickUpAddressParser.linkedAddresses)
  },
  async updateAddresses ({ serviceId, pickUpAddressIds }) {
    return await servicePickUpAddressDomain.updateAddresses({ serviceId, pickUpAddressIds })
  }
}
