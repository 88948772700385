import { convertToFormData } from '@bonitour/common-functions'
import { orbCore } from 'services/Orb/Service'
import { CombinedExperienceModel } from './Model'
import { CombinedExperienceParser } from './Parser'

const { combinedExperienceDomain, sellableCombinedExperienceDomain } = orbCore

export const CombinedExperienceService = {
  create: async (data) => combinedExperienceDomain
    .create(convertToFormData(CombinedExperienceModel.create(data)))
    .then(CombinedExperienceParser.extractExperience),
  update: async (id, data) => combinedExperienceDomain
    .update(id, convertToFormData(CombinedExperienceModel.update(data))),
  get: async (id, isForEdition = false) => combinedExperienceDomain
    .get(id)
    .then((data) => CombinedExperienceParser.extractExperience(data, isForEdition)),
  getSellable: async (id) => sellableCombinedExperienceDomain
    .getSellable(id)
    .then(CombinedExperienceParser.extractExperience),
  list: async (data) => combinedExperienceDomain
    .list(CombinedExperienceModel.list(data))
    .then(CombinedExperienceParser.allExperiences),
  listSellable: async (data) => sellableCombinedExperienceDomain
    .listSellable(CombinedExperienceModel.list(data))
    .then(CombinedExperienceParser.allExperiences)
}
