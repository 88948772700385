import { string, object, number, lazy } from 'yup'

const mapRules = (map, rule) => Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {})

export const updateFeeSchema = () => object().shape({
  serviceId: string().optional('O serviço é obrigatório'),
  active: string().optional('O status é obrigatório'),
  description: object().shape({
    defaultValue: number().typeError('Campo obrigatório').optional().min(100, 'Deve ser no mínimo 100').max(2000, 'Deve ser no máximo 2000'),
    valueByFee: lazy(map => object(
      mapRules(map, number().typeError('Campo obrigatório').min(100, 'Deve ser no mínimo 100').max(2000, 'Deve ser no máximo 2000'))
    ))
  })
})
