
export const createUpdatePriceModifierModel = (data = {}) => {
  const {
    serviceId,
    active,
    description
  } = data
  const {
    defaultValue,
    valueByFee
  } = description

  return {
    service_id: serviceId,
    active,
    description: {
      default_value: defaultValue ? defaultValue / 100 : 1,
      value_by_fee: Object.keys(valueByFee).reduce((acc, key) => {
        if (valueByFee[key]) {
          acc[key] = valueByFee[key] / 100
        }
        return acc
      }, {})
    }
  }
}
