
import { useToast } from '@bonitour/components'
import { ServicePickUpAddressesService } from 'core/services/ServicePickUpAddress'
import { useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'

export const useServicePickUpAddresses = ({ serviceId }) => {
  const { add: addToast } = useToast()

  const {
    data,
    isLoading,
    isFetching,
    refetch
  } = useQuery(
    `
      servicePickUpAddresses
      service: ${serviceId}
    `
    ,
    async () => await ServicePickUpAddressesService.linkedAddresses({
      serviceId
    }),
    {
      enabled: !!serviceId
    }
  )

  const linkedAddressesIds = useMemo(() => data?.map(({ id }) => id) || [], [data])

  const onLinkPickUpAddresses = useCallback(async ({ serviceId, pickUpAddressIds }) => {
    return await ServicePickUpAddressesService.updateAddresses({ serviceId, pickUpAddressIds }).then(() => {
      refetch()
      addToast('Locais de embarque atualizados com sucesso', 'success')
    }).catch(() => {
      addToast('Erro ao atualizar locais de embarque')
    })
  }, [addToast, refetch])

  return {
    onLinkPickUpAddresses,
    linkedAddressesIds,
    linkedAddresses: data,
    isLoading: isLoading || isFetching
  }
}
