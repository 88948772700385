/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useEffect, useState } from 'react'
import { jsx, css } from '@emotion/core'
import {
  Card,
  flex,
  H3,
  HorizontalInputFormGroup,
  InputWithSuffix,
  ToggleInputGroup
} from '@bonitour/components'
import {
  feePaxTypeCard,
  inputLabel,
  marginTop10,
  width80
} from './FeePaxTypeRow.style'

const width120 = css`
  width: 120px;
`

export const UpdateFeeTypeRow = ({
  errors = {},
  indexName,
  register,
  defaultValue,
  setValue,
  feeName,
  trigger,
  activityFee = {}
}) => {
  const [isActive, setIsActive] = useState(false)

  const onClick = useCallback((checked) => {
    setIsActive(checked)

    if (checked) {
      setValue(`description.valueByFee.${feeName}`, defaultValue)
    } else {
      setValue(`description.valueByFee.${feeName}`, undefined)
    }

    trigger(`description.valueByFee.${feeName}`)
  }, [feeName, setValue, defaultValue, trigger])

  useEffect(() => {
    setValue(`description.valueByFee.${feeName}`, undefined)
  }, [feeName, setValue])

  useEffect(() => {
    const checkActive = activityFee?.description?.valueByFee?.[feeName] !== undefined

    if (checkActive) {
      setValue(`description.valueByFee.${feeName}`, activityFee?.description?.valueByFee?.[feeName])
    }

    setIsActive(checkActive)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Card css={[feePaxTypeCard]}>
        <H3 css={marginTop10}>{indexName}</H3>
        <div css={flex}>
          <ToggleInputGroup
            id={feeName}
            checked={isActive}
            onChange={(e) => onClick(e.target.checked)}
          />
          <HorizontalInputFormGroup
            css={[inputLabel, width80]}
            errorMessage={
              errors?.valueByFee && errors?.valueByFee[feeName]?.message && isActive
                ? errors?.valueByFee[feeName]?.message
                : null
            }
            label=""
          >
            <InputWithSuffix
              css={[
                width120
              ]}
              disabled={!isActive}
              type="number"
              min="100"
              {...register(`description.valueByFee.${feeName}`)}
              name={`description.valueByFee.${feeName}`}
              placeholder={defaultValue}
              defaultValue={defaultValue || 100}
              onChangeWholeEvent
            >
              <span>%</span>
            </InputWithSuffix>

          </HorizontalInputFormGroup>
        </div>
      </Card>
    </>
  )
}
