import { COMBINED_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { serviceImageParser } from 'core/parsers/activityId'
import { getImageUrl } from 'services/Orb/Service'
import { sliceDecimal } from 'utils/number'
import { SafeJSONParse } from 'utils/object'

const service = ({
  id,
  experience_id: experienceId,
  service_id: serviceId,
  optional: isOptional,
  company_id: companyId,
  company_name: companyName,
  discount_percentage: discountPercentage,
  service = {}
}) => {
  const {
    service_image: serviceImage = null,
    service_title: serviceTitle,
    service_id: destructuredServiceId,
    service_company_id: destructuredCompanyId,
    service_company_name: destructuredCompanyName,
    optional: serviceIsOptional,
    type
  } = service

  return {
    id,
    experienceId,
    serviceId: serviceId || destructuredServiceId,
    isOptional: isOptional || serviceIsOptional,
    companyId: companyId || destructuredCompanyId,
    companyName: companyName || destructuredCompanyName,
    serviceImage: getImageUrl(serviceImage),
    serviceTitle,
    type,
    discountPercentage: sliceDecimal(String(discountPercentage * 100), 2)
  }
}

export const CombinedExperienceParser = {
  pagination: ({
    total_entries,
    current_page,
    total_pages
  }) => ({
    totalEntries: total_entries,
    currentPage: current_page,
    totalPages: total_pages
  }),
  experience: ({
    id,
    company_id: companyId,
    company_name: companyName = null,
    title,
    subtitle,
    image,
    description,
    enabled: isEnabled,
    services_has_to_be_on_same_day: isSameDayServices,
    experiences_services: experiences = [],
    vendor_id: vendorId,
    vendor_name: vendorName,
    duration,
    ecommerce_order: order,
    fee_mapping: feeMap,
    pick_up_places: pickupOptions
  }) => {
    const parsedDescription = SafeJSONParse(description)
    return ({
      id,
      companyId: companyId || vendorId,
      companyName: companyName || vendorName,
      name: title,
      subtitle,
      image: getImageUrl(image),
      description: {
        'pt-br': parsedDescription['pt-br'] || '',
        en: parsedDescription.en || '',
        es: parsedDescription.es || ''
      },
      startingPrice: parsedDescription?.starting_price,
      duration,
      order,
      isEnabled,
      isSameDayServices,
      type: COMBINED_EXPERIENCE_TYPE,
      experiences: experiences?.map(service) || [],
      feeMap,
      pickupOptions: Object.fromEntries(
        Object.entries(pickupOptions || {}).map(([key, value]) => [
          key,
          value.map(({ pickup_id: pickupId, label, description }) => ({
            pickupId,
            label,
            description
          }))
        ])
      )
    })
  },
  experienceToForm: ({
    id,
    name,
    subtitle,
    image,
    description,
    experiences = [],
    isEnabled,
    isSameDayServices,
    startingPrice,
    duration,
    order,
    feeMap,
    pickupOptions
  }) => ({
    id,
    title: name,
    subtitle,
    files: serviceImageParser(image),
    description,
    experiences: experiences.map((data) => ({
      id: data.serviceId,
      companyId: data.companyId,
      isOptional: data.isOptional,
      discountPercentage: data.discountPercentage
    })),
    startingPrice: startingPrice ? Number(startingPrice).toFixed(2) : null,
    duration,
    order: order >= 0 ? order : null,
    isEnabled,
    isSameDayServices,
    feeMap: Object.values(feeMap || {}),
    pickupOptions: Object.fromEntries(
      Object.entries(pickupOptions || {}).map(([key, value]) => [
        key,
        value.map(({ pickupId, label, description }) => ({
          pickupPlaceId: pickupId,
          description,
          label,
          enabled: true
        }))
      ])
    )
  }),
  allExperiences: ({
    experiences = [],
    meta
  }) => ({
    experiences: experiences.map(CombinedExperienceParser.experience),
    meta: CombinedExperienceParser.pagination(meta)
  }),
  extractExperience: (data, isForEdition = false) => {
    const {
      experience = {}
    } = data

    const parsedData = CombinedExperienceParser.experience(experience)

    if (!isForEdition) return parsedData

    return CombinedExperienceParser.experienceToForm(parsedData)
  }
}
