import { boolean, string, object, array, number, mixed, lazy } from 'yup'

export const experienceSchema = {
  id: string().required('A seleção de experiência é obrigatória'),
  companyId: string().required('A seleção de experiência é obrigatória'),
  isOptional: boolean().default(false),
  discountPercentage: number()
    .typeError('Deve ser um número')
    .min(0, 'A porcentagem deve ser maior ou igual a 0')
    .max(100, 'A porcentagem deve ser menor que 100')
}

const descriptionRule = (max = 5000) => string()
  .max(max, `A descrição deve ter no máximo ${max} caracteres`)
  .trim()
  .optional()

const labelRule = () => string()
  .required('O nome da tarifa é obrigatório')
  .max(100, 'O nome da tarifa deve ter no máximo 100 caracteres')

export const combinedExperiencesSchema = object().shape({
  title: string()
    .min(1, 'O título deve ter no mínimo 1 caractere')
    .max(64, 'O título deve ter no máximo 64 caracteres')
    .trim()
    .required('O título é obrigatório'),
  subtitle: string()
    .min(1, 'O subtítulo deve ter no mínimo 1 caractere')
    .max(64, 'O subtítulo deve ter no máximo 64 caracteres')
    .trim()
    .required('O subtítulo é obrigatório'),
  description: object().shape({
    'pt-br': descriptionRule(),
    en: descriptionRule(),
    es: descriptionRule()
  }),
  experiences: array()
    .of(object().shape(experienceSchema))
    .min(1, 'Selecione ao menos uma experiência'),
  isEnabled: boolean().default(true),
  isSameDayServices: boolean().default(true),
  files: array().of(mixed()),
  startingPrice: number()
    .nullable()
    .transform((_, value) => value ? parseFloat(String(value).replace(',', '.')) : null)
    .min(0, 'O preço deve ser maior ou igual a 0'),
  duration: number()
    .min(0, 'A duração deve ser maior ou igual a 0')
    .typeError('Deve ser um número')
    .transform((_, value) => Math.round(Number(value))),
  order: number()
    .nullable()
    .transform((_, value) => value ? Math.round(Number(value)) : null)
    .min(0, 'A ordem deve ser maior ou igual a 0')
    .max(999999999, 'A ordem deve ser menor que 999999999'),
  feeMap: array().of(
    object().shape({
      label: object().shape({
        'pt-br': labelRule(),
        en: labelRule(),
        es: labelRule()
      }),
      description: object().shape({
        'pt-br': descriptionRule(750),
        en: descriptionRule(750),
        es: descriptionRule(750)
      }),
      services: lazy((value) => object(
        Object.keys(value)
          .filter(key => value[key] !== undefined)
          .reduce((acc, key) => ({
            ...acc,
            [key]: string().optional().required('Selecione uma tarifa')
          }), {})
      ))
    })
  ),
  pickupOptions: lazy((value) => object(
    Object.keys(value)
      .filter(key => value[key] !== undefined)
      .reduce((acc, key) => ({
        ...acc,
        [key]: array()
          .transform((_, value) => value?.filter(({ enabled }) => enabled))
          .of(
            object().shape({
              pickupPlaceId: string().required('O local de embarque é obrigatório'),
              enabled: boolean().default(false),
              label: object().shape({
                'pt-br': labelRule(),
                en: labelRule(),
                es: labelRule()
              }),
              description: object().shape({
                'pt-br': descriptionRule(750),
                en: descriptionRule(750),
                es: descriptionRule(750)
              })
            })
          )
      }), {})
  ))
})
