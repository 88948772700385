/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react'

import { useCallback, useRef } from 'react'

import { popoverContainerStyle } from '../ActivityModalParts'
import { Popover } from '@bonitour/components'
import { TooltipWrapper } from '../TooltipWrapper'

import { identity } from '@bonitour/common-functions'

import { ReservationListTooltipContent } from './ReservationListTooltipContent'
import { CombinedExperiencePaxSelector } from '../CombinedExperiencePaxSelector'
import { useClickOutside } from '@bonitour/app-functions'

export const CombinedExperienceReservationCellModal = ({
  onStartReservation: emitStartReservation = identity,
  onSelectActivity: emitSelectActivity = identity,
  getSelectedPaxData = identity,
  reservationsDetails = [],
  slotsOccupiedOnDate = 0,
  slotsOccupiedOnHour = 0,
  isEnabledReservationMode = false,
  experience = null,
  color = null,
  startTime = null,
  endTime = null,
  limitOfDay = null,
  activityIndex = null,
  date = null,
  selectedDate = null,
  children,
  customCss
}) => {
  const tooltipRef = useRef(null)
  const [isVisible, setVisibility] = useClickOutside(tooltipRef)
  const toggleVisibility = () => setVisibility(!isVisible)

  const onStartReservationButtonClick = useCallback(() => {
    emitStartReservation()
  }, [emitStartReservation])

  return (
    <TooltipWrapper
      ref={tooltipRef}
      referenceChildren={children}
      isVisible={isVisible}
      toggleVisibility={toggleVisibility}
      customCss={customCss}
    >
      <Popover position="right" css={popoverContainerStyle(22)}>
        {(isEnabledReservationMode)
          ? (<CombinedExperiencePaxSelector
            experience={experience}
            color={color}
            endTime={endTime}
            startTime={startTime}
            onSelectActivity={emitSelectActivity}
            limitOfDay={limitOfDay}
            activityIndex={activityIndex}
            date={date}
            getSelectedPaxData={getSelectedPaxData}
            slotsOccupiedOnDate={slotsOccupiedOnDate}
            slotsOccupiedOnHour={slotsOccupiedOnHour}
            selectedDate={selectedDate}
          />)
          : (
            <ReservationListTooltipContent
              experience={experience}
              color={color}
              endTime={endTime}
              startTime={startTime}
              onStartReservation={onStartReservationButtonClick}
              reservationsDetails={reservationsDetails}
              activityIndex={activityIndex}
            />
          )}
      </Popover>
    </TooltipWrapper>
  )
}
