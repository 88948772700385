/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import { useQuery } from 'hooks/useQuery'
import { useActivity } from 'contexts/Activity'
import { useTransport } from 'hooks/domains/useTransport'
import { GhostPrimaryButton, CogIcon, DatePicker, FlagIcon, flex, flexColumn, flexHorizontallyCentered, H1, LoadingContainer, MapPinIcon, LoadingAnimation } from '@bonitour/components'
import { margin, marginTop, width } from 'assets/styles/global'
import { ActivitySelector } from 'containers/Activity/Selector/Selector'
import { useReadableAddress } from 'containers/Address/Address.hooks'
import VacancyTable from './Table/VacancyTable'
import { BookingMapButtons } from './Structure/BookingMapButtons'
import { BookingMapGuide } from './Structure/BookingMapGuide'
import { useOffsetHook } from './hooks/useOffsetHook'
import { useBookingMapDateTime } from './hooks/useBookingMapDateTime'
import { useDateColumns } from './hooks/useDateColumns'
import { useMapSubscriber } from './hooks/useMapSubscriber'
import { useMapDetailSubscriber } from './hooks/useMapDetailSubscriber'
import { useClickOutside } from '@bonitour/app-functions'
import { container, selectActivityContainer, vacancyTableContainer, selectorPadding, datePickerContainer, transportDetails, configIcon, relative, buttonCurrentDate, limberSectionWrapper } from './BookingMap.styles'
import { BookingMapOptions } from './BookingMapOptions'
import { useCallback, useState, useEffect, useMemo, useRef } from 'react'
import { useCompany } from 'contexts/Company'
import { useHistory } from 'react-router-dom'
import { SafeDate } from '@binamik/js-providers'
import { useRouteByType } from 'hooks/useRouteByType'
import { COMBINED_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE, LIMBER_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { BookingMapOperationProgress } from 'components/BookingMap/BookingMapOperationProgress'
import { ActionBar as CombinedExperienceActionBar, ServiceCombinedExperienceList } from 'domains/CombinedExperience/components'
import { BookingMapInfoProvider, useBookingMapInfo } from './contexts/useBookingMapInfo'
import { identity } from '@bonitour/common-functions'
import { LimberSection } from 'containers/Booking/Form/LimberSection/LimberSection'
import { SafeJSONParse } from 'utils/object'
import { PickUpAddressSection } from 'containers/Booking/Form/PickUpAddressSection/PickUpAddressSection'
import { useFeatureFlag } from 'contexts/Feature'

export const DEFAULT_DATE_LIMIT = 7
export const DEFAULT_LIMBER_DATE_LIMIT = 5

const noMargin = margin(0, 0, 0, 0)
const defaultFlexGrow = css`
  flex-grow: 0;
`
const marginTop20 = marginTop(20)

const BookingMap = () => {
  const { push } = useHistory()
  const { activity } = useActivity()
  const { id: companyId } = useCompany()

  const [{ date, email, pickupPlaceIds, pickupPlaceId: queryPickupPlaceId }, { changeQueryParam }] = useQuery()

  const {
    experiences = [],
    pickupOptions = []
  } = activity || {}

  const {
    isTransport,
    isOfflineExperience,
    isCombinedExperience,
    isLimber
  } = useMemo(() => ({
    isTransport: activity?.type === TRANSPORT_TYPE,
    isOfflineExperience: activity?.type === OFFLINE_EXPERIENCE_TYPE,
    isCombinedExperience: activity?.type === COMBINED_EXPERIENCE_TYPE,
    isLimber: activity?.type === LIMBER_EXPERIENCE_TYPE
  }), [activity.type])

  const hasLimberService = useMemo(
    () => isCombinedExperience && experiences.some(({ type }) => type === LIMBER_EXPERIENCE_TYPE),
    [experiences, isCombinedExperience]
  )

  const [_enabled, { vacancyMapDateLimit }, isFlagReady, isFlagTimedOut] = useFeatureFlag('orb-limber-integration')
  const limberDateLimit = useMemo(
    () => (!isFlagReady && !isFlagTimedOut) ? 0 : (vacancyMapDateLimit || DEFAULT_LIMBER_DATE_LIMIT),
    [isFlagReady, isFlagTimedOut, vacancyMapDateLimit]
  )
  const [tableReference, offset] = useOffsetHook(true, (isLimber || hasLimberService) ? limberDateLimit : DEFAULT_DATE_LIMIT)

  const limberData = useMemo(() => isLimber && activity.description ? SafeJSONParse(activity.description).limber_data : {}, [isLimber, activity.description])
  const [pickupPlaceId, setPickupPlaceId] = useState('')
  const [pickupPlaceIdByService, setPickupPlaceIdByService] = useState({})

  const {
    selectedHour,
    referenceDate,
    setReferenceDate,
    changeHour,
    changeToPrevious,
    changeToNext
  } = useBookingMapDateTime(offset, date)
  const dateColumns = useDateColumns(referenceDate, offset)
  const { registries, prices, isLoading, activitiesRegistryAndPrice, activitiesColors, debouncedDates } = useMapSubscriber(
    dateColumns, true, pickupPlaceId, pickupPlaceIdByService
  )
  const tableDetailedData = useMapDetailSubscriber(activity, debouncedDates, selectedHour)

  const transport = useTransport(activity)
  const { longString: originAddress } = useReadableAddress(transport?.transportBase?.originInfo || {})
  const { longString: destinationAddress } = useReadableAddress(transport?.transportBase?.destinationInfo || {})

  const configReference = useRef()
  const [isConfigVisible, setConfigVisibility] = useClickOutside(configReference)
  const onConfigClick = () => setConfigVisibility(!isConfigVisible)
  const isActivityOwner = activity?.companyId === companyId

  const onExceptionalDayEditClick = date => () => {
    const activityType = isTransport ? 'transport' : 'activity'
    return push(`/${activityType}/${activity?.id}/bookings-day-config?date=${date}`)
  }

  const handleSetToCurrentDate = useCallback(() => {
    setReferenceDate(new SafeDate()?.jsDate)
  }, [setReferenceDate])

  const isCurrentDate = useMemo(
    () => new SafeDate()?.jsDate?.getDate() === referenceDate?.getDate(),
    [referenceDate]
  )

  const noHasDataForShow = useMemo(() => Object.keys(activitiesRegistryAndPrice || {}).length === 0 && registries?.length === 0 && prices?.length === 0 && isLoading, [activitiesRegistryAndPrice, prices?.length, registries?.length, isLoading])

  useRouteByType(`${activity?.id}/bookings`)

  const {
    loadFeePrices,
    isEnabledReservationMode,
    totalTickets,
    totalPrice,
    getInvalidActivities,
    selectedActivities,
    resetBookingMapInfo,
    getIsAllServicesInSameDay,
    onSubmit,
    getExperienceQueryParams
  } = useBookingMapInfo()

  const changePickupPlaceId = useCallback(pickupPlaceId => {
    if (isLimber) {
      changeQueryParam('pickupPlaceId', pickupPlaceId)
    }
    setPickupPlaceId(pickupPlaceId)
  }, [changeQueryParam, isLimber])

  useEffect(() => {
    if (queryPickupPlaceId && !pickupPlaceId && isLimber) {
      setPickupPlaceId(queryPickupPlaceId)
    }
  }, [queryPickupPlaceId, pickupPlaceId, isLimber])

  const changePickupPlaceIds = useCallback((pickupPlaceId, serviceId) => {
    setPickupPlaceIdByService(
      old => {
        const pickupPlaceByServiceId = {
          ...old,
          [serviceId]: pickupPlaceId
        }
        const pickupPlaceByServiceIndex = Object.fromEntries(
          Object.entries(pickupPlaceByServiceId)
            .map(([id, value]) => [experiences.findIndex(({ serviceId }) => serviceId === id), value])
        )
        changeQueryParam('pickupPlaceIds', JSON.stringify(pickupPlaceByServiceIndex))
        return pickupPlaceByServiceId
      }
    )
  }, [changeQueryParam, experiences])

  const displayCombinedExperienceList = useMemo(
    () => isCombinedExperience && experiences?.length && !(pickupPlaceIds && !Object.keys(pickupPlaceIdByService).length),
    [isCombinedExperience, pickupPlaceIds, pickupPlaceIdByService, experiences]
  )

  useEffect(() => {
    if (experiences?.length && pickupPlaceIds && !Object.keys(pickupPlaceIdByService).length) {
      const parsedPickupPlaceIds = Object.fromEntries(
        Object.entries(JSON.parse(pickupPlaceIds))
          .map(([index, value]) => [experiences?.[index]?.serviceId, value])
      )
      setPickupPlaceIdByService(parsedPickupPlaceIds)
    }
  }, [pickupPlaceIds, pickupPlaceIdByService, experiences])

  const changeServicePickUpAddress = useCallback((pickUpAddressId) => {
    changeQueryParam('pickupPlaceId', pickUpAddressId)
  }, [changeQueryParam])

  useEffect(() => {
    loadFeePrices({
      initialFeePrices: activitiesRegistryAndPrice
    })
  }, [activitiesRegistryAndPrice, loadFeePrices])

  if (!activity?.type || isOfflineExperience) {
    return (
      <LoadingContainer>
        <LoadingAnimation />
      </LoadingContainer>
    )
  }

  return (
    <>
      <div css={container}>
        <div css={selectActivityContainer}>
          <H1 css={noMargin}>Mapa de Vagas</H1>
          <ActivitySelector
            css={selectorPadding}
            subdomain='bookings'
            email={email}
            onChangeActivity={isCombinedExperience ? resetBookingMapInfo : identity} />
        </div>
        {isLimber &&
          <div css={[flexHorizontallyCentered, limberSectionWrapper]}>
            <LimberSection
              css={noMargin}
              limberData={limberData}
              pickupPlaceId={pickupPlaceId}
              onChange={changePickupPlaceId}
              bookingMapStyle
            />
          </div>
        }

        {isTransport &&
          <PickUpAddressSection
            onChange={changeServicePickUpAddress}
            serviceId={activity?.id}
            selectedPickUpAddressId={queryPickupPlaceId}
            bookingMapStyle
          />
        }
        <div css={flexHorizontallyCentered}>
          <GhostPrimaryButton
            type='button'
            onClick={handleSetToCurrentDate}
            css={buttonCurrentDate}
            disabled={isCurrentDate}
          >
            Hoje
          </GhostPrimaryButton>
          <DatePicker onChange={setReferenceDate} value={referenceDate} css={datePickerContainer} />
          {!isCombinedExperience && isActivityOwner &&
            <div ref={configReference} css={relative}>
              <BookingMapOptions
                enabled={isConfigVisible}
                onClose={onConfigClick}
                referenceDate={referenceDate}
              />
              <CogIcon css={configIcon} onClick={onConfigClick} />
            </div>}
        </div>
      </div>

      <div css={[flex, flexColumn, defaultFlexGrow]}>
        {transport && (
          <div css={transportDetails}>
            <div>
              <MapPinIcon />
              <b>Local de Saída:</b> {originAddress}
            </div>
            <div>
              <FlagIcon />
              <b>Local de Chegada:</b> {destinationAddress}
            </div>
          </div>
        )}

        {displayCombinedExperienceList
          ? <ServiceCombinedExperienceList
            activitiesColors={activitiesColors}
            experiences={experiences}
            experiencesPriceInfo={activitiesRegistryAndPrice}
            customCss={[marginTop20]}
            canShowTotals={true}
            pickupPlaceIdByService={pickupPlaceIdByService}
            changePickupPlaceId={changePickupPlaceIds}
            pickupOptions={pickupOptions}
            servicesVacanciesData={activitiesRegistryAndPrice}
          />
          : null}
        <BookingMapOperationProgress
          customCss={[!isTransport && marginTop(15), width(100)]}
        />
      </div>

      <div css={vacancyTableContainer} ref={tableReference}>
        <BookingMapButtons
          onClickPrevious={changeToPrevious}
          onNextClick={changeToNext}
          offset={offset}
        />
        <VacancyTable
          date={referenceDate}
          dateColumns={dateColumns}
          debouncedDateColumns={debouncedDates}
          tableData={registries}
          tablePrices={prices}
          tableDetailedData={tableDetailedData}
          isLoading={isLoading || noHasDataForShow}
          currentExpandedRowLabel={selectedHour}
          onExpand={changeHour}
          onExceptionalDayEditClick={onExceptionalDayEditClick}
          isActivityOwner={isActivityOwner}
          activitiesRegistryAndPrice={activitiesRegistryAndPrice}
          isCombinedExperience={isCombinedExperience}
          pickupPlaceId={pickupPlaceId || queryPickupPlaceId}
        />
        <BookingMapGuide isCombinedExperience={isCombinedExperience} activitiesColors={activitiesColors}/>
        <CombinedExperienceActionBar
          isEnabledReservationMode={isEnabledReservationMode}
          totalPaxSelected={totalTickets}
          totalPrice={totalPrice}
          getInvalidActivities={getInvalidActivities}
          getIsAllServicesInSameDay={getIsAllServicesInSameDay}
          selectedActivities={selectedActivities}
          onNewReservation={onSubmit}
          getExperienceQueryParams={getExperienceQueryParams}
        />
      </div>
    </>
  )
}

export const ReservationMap = () => {
  return <BookingMapInfoProvider>
    <BookingMap />
  </BookingMapInfoProvider>
}
