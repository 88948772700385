import { deepGetOrElse } from 'deep-getter'

const experienceService = ({
  id: service_id,
  companyId: company_id,
  isOptional: optional = false,
  discountPercentage = 0
}) => {
  const parsedPercetange = (discountPercentage / 100).toFixed(4)

  return {
    service_id,
    company_id,
    optional,
    discount_percentage: parsedPercetange
  }
}

const feeMapItem = ({
  label,
  description,
  services
}, index) => ([
  `fee-${index}`,
  {
    label: {
      'pt-br': label['pt-br'] || '',
      en: label.en || '',
      es: label.es || ''
    },
    description: {
      'pt-br': description['pt-br'] || '',
      en: description.en || '',
      es: description.es || ''
    },
    services: Object.fromEntries(
      Object.entries(services)
        .filter(([key, value]) => key && value)
    )
  }
])

const servicePickupPlaces = (servicePickupOptions) => servicePickupOptions.map(
  option => ({
    pickup_id: option.pickupPlaceId,
    label: option.label,
    description: option.description
  })
)

const createUpdateModel = ({ create = false } = {}) => ({
  isSameDayServices,
  isEnabled,
  title,
  subtitle,
  description,
  experiences,
  files,
  startingPrice,
  duration,
  order,
  feeMap,
  pickupOptions
}) => {
  const parsedFeeMap = feeMap.map(feeMapItem)
  const serviceIds = experiences.map(({ id }) => id)
  const parsedPickupPlaces = Object.fromEntries(
    Object
      .entries(pickupOptions)
      .filter(([key, value]) => key && value?.length && serviceIds.includes(key))
      .map(([key, value]) => [key, Object.values(servicePickupPlaces(value))])
  )
  return ({
    title,
    subtitle,
    image: files?.length ? deepGetOrElse(files, '0.file', null) : create ? undefined : '',
    description: JSON.stringify({
      'pt-br': description['pt-br'] || '',
      en: description.en || '',
      es: description.es || '',
      starting_price: startingPrice
    }),
    enabled: isEnabled,
    services_has_to_be_on_same_day: isSameDayServices,
    services: experiences.map(experienceService),
    ecommerce_order: parseInt(order || -1),
    duration: parseInt(duration),
    fee_mapping: parsedFeeMap.length ? Object.fromEntries(parsedFeeMap) : '',
    pick_up_places: Object.keys(parsedPickupPlaces || {}).length ? parsedPickupPlaces : ''
  })
}

const parsePagination = ({
  page,
  perPage
}) => ({
  page,
  per_page: perPage
})

const parseListData = ({
  title,
  enabled,
  page,
  perPage
}) => ({
  title,
  enabled,
  ...parsePagination({
    page,
    perPage
  })
})

export const CombinedExperienceModel = {
  create: createUpdateModel({ create: true }),
  update: createUpdateModel(),
  list: parseListData
}
