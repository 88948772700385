
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useState } from 'react'
import { useActivity } from 'contexts/Activity'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'hooks/useQuery'
import { getMinHash } from 'utils/object'

const combinedExperienceBookingMapSchema = yup.object().shape({
  selectedActivities: yup.array()
    .of(yup.object().shape({
      serviceId: yup.string().required(),
      date: yup.string().required(),
      hour: yup.string().required(),
      feeName: yup.string().required(),
      quantity: yup.number().required()
    }))
})

export const useCombinedExperienceForm = () => {
  const [isEnabledReservationMode, toggleReservationMode] = useState(false)

  const { push } = useHistory()
  const [{ pickupPlaceIds }, { changeQueriesParam }] = useQuery()

  const {
    watch,
    setValue,
    reset,
    handleSubmit
  } = useForm({
    resolver: yupResolver(combinedExperienceBookingMapSchema, {
      stripUnknown: true,
      abortEarly: false
    }),
    defaultValues: {
      selectedActivities: []
    },
    values: {
      selectedActivities: []
    }
  })

  const { activity } = useActivity()

  const {
    id,
    experiences = []
  } = activity || {}

  const selectedActivitiesValues = watch('selectedActivities')

  const getFormParamsObject = useCallback((activitiesData = []) => {
    const processedExperiences = new Set()

    const activitySelectedSlots = activitiesData.reduce((acc, { date, hour, serviceId }) => {
      const identifier = `${date}_${hour}_${serviceId}`

      if (processedExperiences.has(identifier)) return acc

      processedExperiences.add(identifier)

      const allInSameDatetime = activitiesData
        .filter(exp => exp.date === date && exp.hour === hour && exp.serviceId === serviceId)
        .reduce((newAcc, { feeName, quantity }) => {
          newAcc.push({ [`${date}_${hour}_${feeName}`]: quantity })
          return newAcc
        }, [])

      if (acc[serviceId]) {
        acc[serviceId] = {
          ...acc[serviceId],
          [`${date}_${hour}`]: allInSameDatetime
        }
      } else {
        acc[serviceId] = {
          [`${date}_${hour}`]: allInSameDatetime
        }
      }

      return acc
    }, {})

    const selectedSlotsWithEmptyValues = experiences.reduce((acc, { serviceId }) => ({
      ...acc,
      [serviceId]: activitySelectedSlots[serviceId] || {}
    }), {})

    const slotsArray = Object.keys(selectedSlotsWithEmptyValues).map(serviceId => ({
      serviceId,
      slots: selectedSlotsWithEmptyValues[serviceId]
    }))

    slotsArray.sort((a, b) => {
      const indexA = experiences.findIndex(exp => exp.serviceId === a.serviceId)
      const indexB = experiences.findIndex(exp => exp.serviceId === b.serviceId)
      return indexA - indexB
    })

    const sortedSlots = slotsArray.reduce((acc, { slots }) => {
      acc.push(Object.values(slots))
      return acc
    }, [])

    return sortedSlots
  }, [experiences])

  const changeSelectedActivities = useCallback((newSelectedActivities) => {
    const slotsObj = getFormParamsObject(newSelectedActivities)
    changeQueriesParam({
      slots: JSON.stringify(slotsObj),
      slotsKey: getMinHash(slotsObj)
    })
    const selectedWithQuantity = newSelectedActivities.filter(activity => activity.quantity > 0)
    setValue('selectedActivities', selectedWithQuantity)
  }, [changeQueriesParam, getFormParamsObject, setValue])

  const onSelectActivity = useCallback(({ id, date, hour, feeName, quantity, limitOfDay }) => {
    const filter = (activity) => activity.serviceId === id && activity.date === date && activity.hour === hour && activity.feeName === feeName

    const sameActivityDataIndex = selectedActivitiesValues.findIndex(filter)

    const selectionInfo = {
      isAdded: false,
      extra: null,
      currentQuantity: 0
    }

    const totalQuantity = selectedActivitiesValues.reduce((acc, activity) => {
      if (activity.serviceId === id && activity.date === date) {
        acc += activity.quantity
      }

      return acc
    }, 0)

    if (sameActivityDataIndex !== -1) {
      const currentQuantity = selectedActivitiesValues[sameActivityDataIndex].quantity

      const newTotalQuantity = (totalQuantity - currentQuantity) + quantity

      if (newTotalQuantity === 0) {
        selectedActivitiesValues.splice(sameActivityDataIndex, 1)
        selectionInfo.isAdded = true
        changeSelectedActivities(selectedActivitiesValues)
        return selectionInfo
      }

      if (newTotalQuantity > limitOfDay) {
        selectionInfo.extra = `${newTotalQuantity}/${limitOfDay}`
        selectionInfo.currentQuantity = currentQuantity
        return selectionInfo
      }

      selectedActivitiesValues[sameActivityDataIndex].quantity = quantity

      changeSelectedActivities(selectedActivitiesValues)

      selectionInfo.isAdded = true

      return selectionInfo
    } else {
      const newTotalQuantity = totalQuantity + quantity

      if (newTotalQuantity > limitOfDay) {
        selectionInfo.extra = `${newTotalQuantity}/${limitOfDay}`
        return selectionInfo
      }

      selectedActivitiesValues
        .push({ serviceId: id, date, hour, feeName, quantity })

      changeSelectedActivities(selectedActivitiesValues)

      selectionInfo.isAdded = true

      return selectionInfo
    }
  }, [changeSelectedActivities, selectedActivitiesValues])

  const getExperienceQueryParams = useCallback(() => {
    const formParamsObject = getFormParamsObject(selectedActivitiesValues)
    const formQueryParams = JSON.stringify(formParamsObject)
    const slotsKey = getMinHash(formParamsObject)
    const pickupPlaceIdsQuery = pickupPlaceIds ? `&pickupPlaceIds=${encodeURIComponent(pickupPlaceIds)}` : ''

    return `?slots=${encodeURIComponent(formQueryParams)}&slotsKey=${slotsKey}${pickupPlaceIdsQuery}`
  }, [getFormParamsObject, selectedActivitiesValues, pickupPlaceIds])

  const onStartReservation = useCallback(() => {
    const experienceQueryParams = getExperienceQueryParams()
    push(`/combined-experience/${id}/bookings/create${experienceQueryParams}`)
  }, [getExperienceQueryParams, id, push])

  const onSubmit = handleSubmit(onStartReservation)

  return {
    onSelectActivity,
    values: {
      selectedActivities: selectedActivitiesValues
    },
    toggleReservationMode,
    isEnabledReservationMode,
    resetForm: reset,
    onSubmit,
    setValue,
    getExperienceQueryParams
  }
}
