/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, LoadingAnimation, useToast } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { loadingContainerOverlay, width100, positionRelative } from 'assets/styles/global'
import { CombinedExperienceFormServiceSelector } from './CombinedExperienceFormServiceSelector'
import { CombinedExperienceBaseForm } from './CombinedExperienceBaseForm'
import { CombinedExperienceFormConfirmationDialog } from './CombinedExperienceFormConfirmationDialog'
import { combinedExperiencesSchema } from 'core/schemas/combinedExperiences'
import { CombinedExperienceFormEcommerce } from './CombinedExperienceFormEcommerce'
import { CombinedExperienceFormPickup } from './CombinedExperienceFormPickup'

const INITIAL_FORM_DATA = {
  title: '',
  subtitle: '',
  description: {},
  experiences: [],
  isEnabled: true,
  isSameDayServices: true,
  startingPrice: 0,
  duration: 0,
  order: null,
  feeMap: [],
  pickupOptions: {}
}

const REMOVE_SERVICE_MODAL_INITIAL_DATA = {
  isOpenedRemoveModal: false,
  selectedServiceIdToRemove: null,
  selectedServiceNameToRemove: null
}

const REPLACE_SERVICE_MODAL_INITIAL_DATA = {
  isOpenedReplaceModal: false,
  callbackArgs: [],
  fromLabel: null,
  toLabel: null
}

const getErrorMessage = (error) => Object.keys(error).reduce((acc, curr) => !acc ? error?.[curr].message : '', '')

export const CombinedExperienceForm = ({
  onSubmit = identity,
  initialData = null,
  isLoading = false,
  experienceList = [],
  isLoadingExperienceList = false,
  isEdition = false,
  isSubmitSuccess = false
}) => {
  const { add: addToast } = useToast()

  const [removeServiceData, setRemoveServiceData] = useState(REMOVE_SERVICE_MODAL_INITIAL_DATA)

  const [replaceServiceData, setReplaceServiceData] = useState(REPLACE_SERVICE_MODAL_INITIAL_DATA)

  const {
    handleSubmit,
    register,
    formState,
    setValue,
    watch,
    clearErrors,
    reset,
    control
  } = useForm({
    defaultValues: initialData || INITIAL_FORM_DATA,
    resolver: yupResolver(combinedExperiencesSchema, {
      stripUnknown: true
    })
  })

  const onFail = useCallback((err) => {
    console.error(err)
    addToast('Houve um erro, confira o formulário e tente novamente', 'error')
  }, [addToast])

  const onSubmitClick = handleSubmit(onSubmit, onFail)

  const selectedExperiences = watch('experiences')

  const isSameDayServices = watch('isSameDayServices')

  const isEnabledExperience = watch('isEnabled')

  const {
    allExperiences,
    allSelectedExperiences,
    isNonEmptySelectedExperiences,
    isValidExperiences,
    isEmptyUnselectedExperienceOptions
  } = useMemo(() => {
    const isValidExperiences = selectedExperiences.every(({ id, companyId }) => Boolean(id) && Boolean(companyId))

    const allSelectedExperiences = selectedExperiences.map((selectedExperienceData, selectedExperienceIndex) => {
      const error = (formState?.errors.experiences || [])[selectedExperienceIndex]

      return {
        ...selectedExperienceData,
        currentError: error && getErrorMessage(error)
      }
    })

    const allExperiences = experienceList.reduce((acc, { id, name, image, companyName, companyId }) => {
      return [...acc, { image, id, name, companyName, companyId }]
    }, [])

    return {
      allExperiences,
      isNonEmptySelectedExperiences: (selectedExperiences || []).length > 0,
      isValidExperiences,
      isEmptyUnselectedExperienceOptions: null,
      allSelectedExperiences
    }
  }, [selectedExperiences, experienceList, formState?.errors.experiences])

  const onAddService = useCallback((id) => {
    if (selectedExperiences.some(({ id: experienceId }) => experienceId === id)) {
      return addToast('Experiência já adicionada', 'warning')
    }
    const { companyId } = allExperiences?.find(({ id: experienceId }) => experienceId === id)
    const newSelectedExperiencesList = [...(selectedExperiences || []), { id, companyId }]
    setValue('experiences', newSelectedExperiencesList)
    setValue('duration', 0)
    setValue('startingPrice', null)
  }, [addToast, selectedExperiences, setValue, allExperiences])

  const onServiceRemove = useCallback((id) => {
    if (id === '') {
      const newFormExperienceList = [...(selectedExperiences || [])]

      newFormExperienceList.pop()

      return setValue('experiences', newFormExperienceList)
    }
    const newSelectedExperiencesList = selectedExperiences.filter(({ id: experienceId }) => experienceId !== id)

    setValue('duration', 0)
    setValue('startingPrice', null)
    setValue('experiences', newSelectedExperiencesList)
  }, [selectedExperiences, setValue])

  const replaceService = useCallback((currentServiceIndex = '', newServiceData = {}, lastServiceIndex = '') => {
    let tempExperiences = [...selectedExperiences]

    tempExperiences[currentServiceIndex] = newServiceData

    tempExperiences = tempExperiences.filter((_data, index) => index !== lastServiceIndex)

    setValue('experiences', tempExperiences)
  }, [selectedExperiences, setValue])

  const toggleSameDayServices = useCallback(() => {
    setValue('isSameDayServices', !isSameDayServices)
  }, [isSameDayServices, setValue])

  const toggleIsEnabledExperience = useCallback(() => {
    const newValue = !isEnabledExperience

    if (newValue) {
      addToast('Experiência ativada', 'success')
    } else {
      addToast('Experiência desativada', 'warning')
    }

    setValue('isEnabled', newValue)
  }, [addToast, isEnabledExperience, setValue])

  const handleCloseReplaceServiceModal = useCallback(() => {
    setReplaceServiceData(REPLACE_SERVICE_MODAL_INITIAL_DATA)
  }, [])

  const handleOpenReplaceServiceModal = useCallback((...callbackArgs) => {
    const [indexOfLastService, _newServiceData, indexOfService] = callbackArgs

    const fromLabel = allExperiences.find(({ id: experienceId }) => experienceId === selectedExperiences?.[indexOfLastService]?.id)?.name

    const toLabel = allExperiences.find(({ id: experienceId }) => experienceId === selectedExperiences[indexOfService].id).name

    setReplaceServiceData({
      isOpenedReplaceModal: true,
      callbackArgs: [...callbackArgs],
      fromLabel,
      toLabel
    })
  }, [allExperiences, selectedExperiences])

  const handleSuccessReplaceServiceModal = useCallback(() => {
    const { fromLabel, toLabel } = replaceServiceData
    replaceService(...replaceServiceData.callbackArgs)
    addToast(`Experiência ${fromLabel} substituída por ${toLabel}`, 'warning')
    handleCloseReplaceServiceModal()
  }, [replaceServiceData, replaceService, addToast, handleCloseReplaceServiceModal])

  const handleCloseRemoveServiceModal = useCallback(() => {
    setRemoveServiceData(REMOVE_SERVICE_MODAL_INITIAL_DATA)
  }, [])

  const handleOpenRemoveServiceModal = useCallback((id) => {
    if (isLoading) return

    if (!id) {
      onServiceRemove(id)
      return
    }

    const { name } = allExperiences.find(({ id: experienceId }) => experienceId === id)

    setRemoveServiceData({
      isOpenedRemoveModal: true,
      selectedServiceIdToRemove: id,
      selectedServiceNameToRemove: name
    })
  }, [allExperiences, isLoading, onServiceRemove])

  const handleSuccessRemoveServiceModal = useCallback(() => {
    onServiceRemove(removeServiceData.selectedServiceIdToRemove)
    handleCloseRemoveServiceModal()
  }, [onServiceRemove, removeServiceData.selectedServiceIdToRemove, handleCloseRemoveServiceModal])

  const onServiceEdit = useCallback(({ id, lastId, isOptional, discountPercentage }) => {
    const filterForFind = (idToSearch) => ({ id: experienceId }) => experienceId === idToSearch

    const indexOfService = selectedExperiences.findIndex(filterForFind(id))

    const indexOfLastService = selectedExperiences.findIndex(filterForFind(lastId))

    if (lastId && !id && typeof isOptional === 'boolean') {
      const newSelectedExperiences = [...selectedExperiences]
      selectedExperiences[indexOfLastService].isOptional = isOptional
      return setValue('experiences', newSelectedExperiences)
    }

    if (lastId && !id && ['string', 'number'].includes(typeof discountPercentage)) {
      const newSelectedExperiences = [...selectedExperiences]
      selectedExperiences[indexOfLastService].discountPercentage = discountPercentage
      return setValue('experiences', newSelectedExperiences)
    }

    if (id !== lastId) {
      const hasServiceInSelectedServices = selectedExperiences.find(filterForFind(id))

      if (hasServiceInSelectedServices) {
        const replaceServiceData = selectedExperiences[indexOfService]
        return handleOpenReplaceServiceModal(indexOfLastService, replaceServiceData, indexOfService)
      } else {
        const { image } = allExperiences.find(filterForFind(id))
        selectedExperiences[indexOfLastService] = {
          image,
          id
        }
      }

      if (!lastId && id) {
        clearErrors(`experiences.${indexOfLastService}.id`)
      }

      return setValue('experiences', selectedExperiences, {
        shouldValidate: true,
        shouldTouch: true
      })
    }

    setValue('experiences', selectedExperiences)
  }, [allExperiences, clearErrors, handleOpenReplaceServiceModal, selectedExperiences, setValue])

  useEffect(() => {
    if (!isLoading && isSubmitSuccess) {
      reset()
    }
  }, [isLoading, isSubmitSuccess, reset])

  useEffect(() => {
    if (!initialData) return
    Object.keys(initialData).forEach((key) => {
      setValue(key, initialData[key])
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData])

  return <Card css={positionRelative}>
    {isLoading && <LoadingAnimation css={loadingContainerOverlay} />}
    <CombinedExperienceBaseForm
      errors={formState?.errors}
      register={register}
      watch={watch}
      setValue={setValue}
      isLoading={isLoading}
      isEnabledExperience={isEnabledExperience}
      toggleIsEnabledExperience={toggleIsEnabledExperience}
    />
    <CombinedExperienceFormServiceSelector
      servicesAdded={allSelectedExperiences}
      allServices={allExperiences}
      toggleSameDayServices={toggleSameDayServices}
      onExperienceEdit={onServiceEdit}
      onExperienceRemove={handleOpenRemoveServiceModal}
      onAddService={onAddService}
      isLoadingExperiencesList={isLoadingExperienceList}
      isValidForm={isValidExperiences}
      isCheckedSameDayServices={isSameDayServices}
      isLoading={isLoading}
      isEmptyUnselectedExperienceOptions={isEmptyUnselectedExperienceOptions}
    />
    <CombinedExperienceFormPickup
      control={control}
      register={register}
      errors={formState?.errors}
      isLoading={isLoading}
      selectedServices={selectedExperiences}
      setValue={setValue}
      watch={watch}
    />
    <CombinedExperienceFormEcommerce
      isLoading={isLoading}
      register={register}
      errors={formState?.errors}
      control={control}
      selectedServices={selectedExperiences}
      setValue={setValue}
      watch={watch}
    />
    {!isLoadingExperienceList && isNonEmptySelectedExperiences && (
      <Button css={[width100]} onClick={onSubmitClick} disabled={isLoading}>{isEdition ? 'Salvar experiência combinada' : 'Criar experiência combinada'}</Button>
    )}
    <CombinedExperienceFormConfirmationDialog
      isVisible={removeServiceData.isOpenedRemoveModal}
      title='Remover experiência'
      onClose={handleCloseRemoveServiceModal}
      onSuccess={handleSuccessRemoveServiceModal}>
       Confirme a remoção da experiência <b>{removeServiceData.selectedServiceNameToRemove}</b>.
    </CombinedExperienceFormConfirmationDialog>
    <CombinedExperienceFormConfirmationDialog
      isVisible={replaceServiceData.isOpenedReplaceModal}
      title='Substituir experiência'
      onClose={handleCloseReplaceServiceModal}
      onSuccess={handleSuccessReplaceServiceModal}>
        Confirme a substituição da experiência <b>{replaceServiceData.fromLabel}</b> pela experiência <b>{replaceServiceData.toLabel}</b>.
    </CombinedExperienceFormConfirmationDialog>
  </Card>
}
