/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { Card, Dialog, EditInfoIcon, HorizontalInputFormGroup, ToggleInputGroup } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useFieldArray } from 'react-hook-form'
import { descriptionAreaStyle, noPickupPlaceLabelStyle, pickupDialogContainerStyle, pickupOptionsListWrapperStyle, pickupPlaceCardStyle, pickupPlaceCountStyle, pickupPlaceFieldsWrapperStyle, toggleGroupStyle } from './CombinedExperienceForm.styles'
import { MultiLanguageInput, LANG_KEYS } from 'components/MultiLanguageInput/MultiLanguageInput'
import { useCallback, useEffect, useMemo } from 'react'
import { ActivityCard } from 'containers/Activity/ActivityCard'

export const CombinedExperienceFormPickupService = ({
  register,
  errors,
  isLoading,
  control,
  service,
  pickupOptions,
  isOpen,
  onClose
}) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `pickupOptions.${service?.id}`
  })

  const fieldById = useCallback(
    (id) => fields.find((field) => field.pickupPlaceId === id),
    [fields]
  )

  useEffect(() => {
    pickupOptions?.forEach((option) => {
      if (!fields.find((field) => field.pickupPlaceId === option.pickupPlaceId)) {
        append({
          pickupPlaceId: option.pickupPlaceId,
          name: option.name,
          description: Object.fromEntries(LANG_KEYS.map((lang) => [lang, option.description])),
          label: Object.fromEntries(LANG_KEYS.map((lang) => [lang, option.name])),
          enabled: false
        })
      }
    })
    fields?.forEach((field, index) => {
      if (!pickupOptions?.find((option) => option.pickupPlaceId === field.pickupPlaceId)) {
        remove(index)
      }
    })
    fields?.forEach((field, index) => {
      if (!field.name) {
        update(index, {
          ...field,
          name: pickupOptions?.find((option) => option.pickupPlaceId === field.pickupPlaceId)?.name || 'SEM NOME'
        })
      }
    })
  }, [fields, pickupOptions, append, remove, update])

  const onToggleClick = useCallback(
    index => event => {
      update(index, { ...fields[index], enabled: event.target.checked })
    },
    [fields, update]
  )

  const activePickupCount = useMemo(
    () => fields.filter(({ enabled }) => enabled).length,
    [fields]
  )
  const inactivePickupCount = useMemo(
    () => fields.length - activePickupCount,
    [fields, activePickupCount]
  )

  const getActiveIndex = useCallback(
    index => {
      const field = fields[index]
      const activeOptions = fields.filter(({ enabled }) => enabled)
      return activeOptions.indexOf(field)
    },
    [fields]
  )

  return (
    <>
      {!(!activePickupCount && !inactivePickupCount)
        ? (
          <div css={pickupPlaceCountStyle}>
            <div>
              {activePickupCount
                ? (
                  <p className='active'>
                    <strong style={{ fontWeight: 600 }}>{activePickupCount} </strong>
                    loca{activePickupCount > 1 ? 'is' : 'l'} de embarque ativo{activePickupCount > 1 ? 's' : ''}{' '}
                  </p>

                )
                : null}
              {inactivePickupCount
                ? (
                  <p className='inactive'>
                    <strong style={{ fontWeight: 600 }}>{inactivePickupCount} </strong>
                    loca{inactivePickupCount > 1 ? 'is' : 'l'} de embarque inativo{inactivePickupCount > 1 ? 's' : ''}{' '}
                  </p>
                )
                : null}
            </div>
            <EditInfoIcon />
          </div>
        )
        : (
          <div css={pickupPlaceCountStyle}>
            <p>Nenhum local de embarque</p>
          </div>
        )}
      <div onClick={e => e.stopPropagation()}>
        <Dialog
          title="Locais de embarque"
          isVisible={isOpen}
          onClose={onClose}
          customContainercss={[pickupDialogContainerStyle]}
        >
          <ActivityCard
            activity={service}
            hideActionLabel
            hasTitleMaxWidth
            disableLink
            disableOnClick
            hideTypeLabel
          />
          <div css={pickupOptionsListWrapperStyle}>
            {fields?.length
              ? (
                fields.map((pickupOption, index) => (
                  <Card key={pickupOption.pickupPlaceId} customCss={[pickupPlaceCardStyle]}>
                    <HorizontalInputFormGroup id={pickupOption?.pickupPlaceId} >
                      <ToggleInputGroup
                        customCss={[toggleGroupStyle]}
                        checked={fieldById(pickupOption?.pickupPlaceId)?.enabled}
                        onChange={onToggleClick(index)}
                      >
                        {pickupOption?.name}
                      </ToggleInputGroup>
                    </HorizontalInputFormGroup>
                    <div css={pickupPlaceFieldsWrapperStyle} className={fieldById(pickupOption?.pickupPlaceId)?.enabled ? '' : 'disabled'}>
                      <MultiLanguageInput
                        label='Nome'
                        isRequired
                        name={`pickupOptions.${service?.id}[${index}].label`}
                        register={register}
                        errors={errors.pickupOptions?.[service?.id]?.[getActiveIndex(index)]?.label}
                        isLoading={isLoading}
                      />
                      <MultiLanguageInput
                        label='Descrição'
                        name={`pickupOptions.${service?.id}[${index}].description`}
                        register={register}
                        errors={errors.pickupOptions?.[service?.id]?.[getActiveIndex(index)]?.description}
                        isLoading={isLoading}
                        textArea
                        customInputStyle={[descriptionAreaStyle]}
                      />
                    </div>
                  </Card>
                ))
              )
              : (
                <p css={noPickupPlaceLabelStyle}>
                  O serviço não possui nenhum local de embarque
                </p>
              )
            }
          </div>
        </Dialog>
      </div>
    </>
  )
}
