/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Button, Column, flexCenteredSpaceBetweenRow, flexColumn, GhostButton, Input, InputFormGroup, InputZipCodeMask, Label, Modal, Row, Select, useToast } from '@bonitour/components'
import { cep, inputGroup, marginBottom20, marginTop7, mobilePaddingLeft0, padding0, paddingLeft0 } from './Pickup.style'
import { useCallback } from 'react'
import { marginLeft, required } from 'assets/styles/global'
import { usePickupForm } from './hooks/usePickupForm'
import { latLngContainer } from './PickUpModal.style'

const marginLeft10 = marginLeft(10)

export const PickupModal = ({
  pickup = {},
  isPickupModalVisible = false,
  onClose: emitCloseClick,
  onSuccess = identity,
  isCoordinatesDisabled
}) => {
  const { add: addToast } = useToast()

  const {
    form,
    errors,
    countries,
    states,
    cities,
    fetchCoordinates,
    onZipCodeBlur,
    onStateChange,
    onCountryChange,
    onInputBlur,
    onInputChange,
    onSubmit
  } = usePickupForm({ pickup, onSuccess, addToast })

  const {
    zipCode = '',
    address = '',
    addressDetail = '',
    district = '',
    country = '',
    state = '',
    city = '',
    latLong = ''
  } = form?.addressInfo ?? {}

  const { name = '' } = form

  const onAddressInputBlur = useCallback(addressInputName => onInputBlur(`addressInfo.${addressInputName}`), [onInputBlur])
  const onAddressInputChange = useCallback(addressInputName => onInputChange(`addressInfo.${addressInputName}`), [onInputChange])

  const handleOnFetchCoordinates = useCallback(() => {
    if (isCoordinatesDisabled) return
    fetchCoordinates()
  }, [fetchCoordinates, isCoordinatesDisabled])

  return (
    <Modal title='Local de embarque' isVisible={isPickupModalVisible} onCloseClick={emitCloseClick}>
      <Row>
        <Column desktop={6} css={padding0}>
          <div css={[flexColumn, inputGroup, marginTop7]}>
            <Label htmlFor='name'>Nome da hospedagem<span css={required}>(Obrigatório)</span></Label>
            <Input
              id='name'
              value={name}
              error={errors.name}
              onChange={onInputChange('name')}
              onBlur={onInputBlur('name')}
            />
          </div>
        </Column>
        <Column desktop={6} css={padding0}>
          <div css={[flexColumn, inputGroup]}>
            <Label htmlFor='addressInfo.zipCode'>CEP</Label>
            <InputZipCodeMask
              id='addressInfo.zipCode'
              value={zipCode === null ? '' : zipCode}
              error={errors.zipCode}
              onChange={onAddressInputChange('zipCode')}
              onBlur={onZipCodeBlur}
            />
          </div>
        </Column>
      </Row>
      <div css={[flexCenteredSpaceBetweenRow, marginBottom20, cep]}>
        <div css={[flexColumn, inputGroup, marginTop7]}>
          <Label htmlFor='addressInfo.address'>Endereço</Label>
          <Input
            id='addressInfo.address'
            value={address}
            error={errors.address}
            onChange={onAddressInputChange('address')}
            onBlur={onAddressInputBlur('address')}
          />
        </div>

        <div css={[flexColumn, inputGroup, marginTop7]}>
          <Label htmlFor='addressInfo.addressDetail'>Número</Label>
          <Input
            id='addressInfo.addressDetail'
            value={addressDetail}
            error={errors.addressDetail}
            onChange={onAddressInputChange('addressDetail')}
            onBlur={onAddressInputBlur('addressDetail')}
          />
        </div>
        <div css={[flexColumn, inputGroup, marginTop7]}>
          <Label htmlFor='addressInfo.district'>Bairro</Label>
          <Input
            id='addressInfo.district'
            value={district}
            error={errors.district}
            onChange={onAddressInputChange('district')}
            onBlur={onAddressInputBlur('district')}
          />
        </div>
      </div>
      <Row css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <Column desktop={4} css={paddingLeft0}>
          <div css={flexColumn}>
            <Label>País</Label>
            <Select
              placeholder='Selecione um país'
              options={countries}
              value={country}
              error={errors.country}
              onChange={onCountryChange}
              onBlur={onAddressInputBlur('country')}
            />
          </div>
        </Column>
        <Column desktop={4} css={[marginTop7, mobilePaddingLeft0]}>
          <div css={flexColumn}>
            <Label>Estado</Label>
            <Select
              value={state}
              placeholder='Selecione um estado'
              options={states}
              error={errors.state}
              onChange={onStateChange}
              onBlur={onAddressInputBlur('state')}
            />
          </div>
        </Column>
        <Column desktop={4} css={[marginTop7, mobilePaddingLeft0]}>
          <div css={flexColumn}>
            <Label>Cidade</Label>
            <Select
              value={city}
              placeholder='Selecione uma cidade'
              options={cities}
              error={errors.city}
              onChange={onAddressInputChange('city')}
              onBlur={onAddressInputBlur('city')}
            />
          </div>
        </Column>
      </Row>

      <Row css={[flexCenteredSpaceBetweenRow, marginBottom20]}>
        <Column desktop={4} css={paddingLeft0}>
          <InputFormGroup label='Coordenadas' errorMessage={errors.addressInfo?.latLong}>
            <div css={latLngContainer}>
              <Input
                disabled={isCoordinatesDisabled}
                value={latLong}
                onChange={onAddressInputChange('latLong')}
                onBlur={onAddressInputBlur('latLong')}
                placeholder='Latitude , Longitude'
              />
              <div className={`button ${isCoordinatesDisabled && 'disabled'}`} onClick={handleOnFetchCoordinates}>Obter coordenadas</div>
            </div>
          </InputFormGroup>
        </Column>
      </Row>
      <div>
        <GhostButton onClick={emitCloseClick}>Cancelar</GhostButton>
        <Button onClick={onSubmit} css={marginLeft10}>Salvar</Button>
      </div>
    </Modal>
  )
}
