/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FaMapPin } from 'react-icons/fa6'

import { FaRegTrashAlt } from 'react-icons/fa'

import { NewPickUpAddressBtn } from './components/NewPickUpAddressButton'
import { cardContainer, emptyCardContainer } from './PickUpAddressesPage.style'
import { identity } from '@bonitour/common-functions'
import { useMemo } from 'react'
import { LoadingAnimation } from '@bonitour/components'

export const PickUpAddressesList = ({ pickUpAddresses = [], isLoading = false, onAdd = identity, onDelete = identity }) => {
  const isEmpty = useMemo(() => !pickUpAddresses?.length, [pickUpAddresses?.length])

  if (isEmpty || isLoading) {
    return <div css={emptyCardContainer}>
      {isLoading
        ? <LoadingAnimation/>
        : <>
            Nenhum local de embarque cadastrado
          <NewPickUpAddressBtn onClick={() => onAdd()} />
        </>}
    </div>
  }

  return (
    <div css={cardContainer}>
      {pickUpAddresses.map(({ id, name, street, supplement, district }) => (
        <div className="address__row" key={id}>
          <div className="address__content">
            <FaMapPin/>

            <span className="name">{name}</span>
            {street && <span className="street">, {street}</span>}
            {supplement && <span className="supplement">, {supplement}</span>}
            {district && <span className="district">, {district}</span>}
          </div>

          <FaRegTrashAlt className='trash__button' onClick={() => onDelete(name, id)} />
        </div>
      ))}
    </div>)
}
