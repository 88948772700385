export const FEE_TYPES = {
  CHILD: 'Criança',
  ADULT: 'Adulto',
  SENIOR: 'Sênior',
  FREE: 'Não pagante',
  HALF: 'Meia',
  REGIONAL: 'Regional',
  COURTESY: 'Cortesia',
  SINGLE: 'Tarifa Única',
  PRIVATE: 'Privativo',
  FOREIGN: 'Estrangeiro'
}

export const PAX_TYPE_NEED_VALIDATION = [FEE_TYPES.CHILD, FEE_TYPES.ADULT, FEE_TYPES.SENIOR, FEE_TYPES.FREE]
export const PAX_TYPE_WHEN_IS_PRIVATE = [FEE_TYPES.CHILD, FEE_TYPES.ADULT, FEE_TYPES.SENIOR, FEE_TYPES.FREE, FEE_TYPES.HALF, FEE_TYPES.REGIONAL, FEE_TYPES.COURTESY]
export const ADITIONAL_PAX = [FEE_TYPES.HALF, FEE_TYPES.REGIONAL, FEE_TYPES.COURTESY]

export const UNIQUE_FEE_FILTER = [FEE_TYPES.SINGLE, FEE_TYPES.PRIVATE]
export const DEFAULT_FEE_FILTER = [FEE_TYPES.ADULT, FEE_TYPES.CHILD, FEE_TYPES.SENIOR]
export const ADITIONAL_FEE_FILTER = [FEE_TYPES.FREE, FEE_TYPES.HALF, FEE_TYPES.REGIONAL, FEE_TYPES.COURTESY, FEE_TYPES.FOREIGN]
