import { getImageUrl } from 'services/Orb/Service'

export const serviceImageParser = (image) =>
  image
    ? [
      {
        id: image,
        src: getImageUrl(image)
      }
    ]
    : []

export const activityIdParser = (activity = {}) => {
  const { id, title: name = '', image } = activity

  const files = serviceImageParser(image)
  return { id, name, files }
}
