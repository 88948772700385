import { orbCore } from 'services/Orb/Service'
import { activityModel, activityAboutModel, activityUpdateModel, complementaryInfoModel } from './Model'
import { ActivityParser } from './Parser'
import { CompanyStore } from 'core/store/Company'
import { convertToFormData } from '@bonitour/common-functions'

const { activityDomain, companiesDomain } = orbCore

export const ActivityService = {
  list (enabled = false) {
    return activityDomain.sellableList(enabled).then(ActivityParser.list)
  },
  create (data) {
    const companyId = CompanyStore.getId()
    const requestPayload = activityModel(data, companyId)
    return activityDomain.create(convertToFormData(requestPayload))
      .then(({ service = {} }) => service)
  },
  get (serviceId = '') {
    return activityDomain.get(serviceId)
      .then(ActivityParser.instance)
  },
  getRestrictions (serviceId = '') {
    return activityDomain.get(serviceId)
      .then(ActivityParser.restrictions)
  },
  getPartners (companyId = '') {
    return companiesDomain.partners(companyId).then(ActivityParser.partners)
  },
  updateMainActivity (activityId, data = {}) {
    const allowNull = true
    const requestPayload = activityUpdateModel(data)
    return activityDomain.updateAsFormData(convertToFormData(requestPayload, allowNull), activityId)
  },
  updateAbout (data = {}, serviceId = '') {
    const requestPayload = activityAboutModel(data)
    return activityDomain.update(requestPayload, serviceId)
  },
  updateActivityComplementaryInfo (data = {}, serviceId = '') {
    const requestPayload = complementaryInfoModel(data)
    return activityDomain.update(requestPayload, serviceId)
  },
  priceModifier (data = {}) {
    return activityDomain.priceModifier(data)
  }
}
