import { useCallback, useState } from 'react'
import { useToast } from '@bonitour/components'
import { PickupAndDropOffService } from 'core/services/PickupAndDropOff'
import { useParams } from 'react-router-dom'
import { head } from '@bonitour/common-functions'

export const usePickup = (updateReservation) => {
  const { add: addToast } = useToast()
  const [isPickupModalVisible, setPickupModalVisibility] = useState(false)
  const [isPickupAutoFillModalVisible, setIsPickupAutoFillModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pickupTickets, setPickupTickets] = useState([])
  const { reservationIdentification: reservationId } = useParams()
  const [pickup, setPickup] = useState({})
  const [ticketIds, setTicketsId] = useState([])
  const [pickupId, setPickupId] = useState('')
  const [serviceId, setServiceId] = useState('')
  const [isPickupCreationOrEdition, setIsPickupCreationOrEdition] = useState('')

  const onOpenAutoFillModal = (selectedTicketsIds, serviceId) => {
    setIsPickupAutoFillModalVisible(true)
    setTicketsId(selectedTicketsIds)
    setServiceId(serviceId)
  }

  const onCloseAutoFillModal = () => {
    setIsPickupAutoFillModalVisible(false)
  }

  const onClosePickupModal = () => {
    setPickup({})
    setPickupModalVisibility(false)
  }

  const onClickPickup = (newTicketIds = ticketIds, newServiceId = serviceId) => {
    setIsPickupCreationOrEdition('create')
    setTicketsId(newTicketIds)
    setServiceId(newServiceId)
    setPickupModalVisibility(true)
  }

  const onEditClickPickup = (pickupDropff, ticketId, pickupId, serviceId) => {
    setIsPickupCreationOrEdition('edit')
    setTicketsId([ticketId])
    setPickupId(pickupId)
    setServiceId(serviceId)
    const {
      postCode: zipCode,
      street: address,
      supplement: addressDetail,
      district,
      countryId: country,
      stateId: state,
      cityId: city,
      name,
      supplement,
      latitude,
      longitude
    } = head(pickupDropff).pickUpDropOffAddress

    const latLong = latitude && longitude ? `${latitude}, ${longitude}` : null

    const addressInfo = {
      zipCode,
      address,
      addressDetail,
      district,
      country,
      state,
      city,
      supplement,
      latLong
    }

    setPickupModalVisibility(true)
    setPickup({ addressInfo, name })
  }

  const onUnlinkPickup = (pickUpDropOffId, ticketId) => {
    setLoading(ticketId)
    PickupAndDropOffService.remove(pickUpDropOffId).then(() => {
      addToast('Local de embarque desvinculado com sucesso', 'success')
      getPickup()
      updateReservation()
    }).catch(() => {
      addToast('Houve um problema no desvinculo do local de embarque')
    }).finally(() => setLoading(false))
  }

  const editPickup = async (pickUpDropOff) => {
    setLoading(ticketIds)
    const ticketId = head(ticketIds) ?? ''
    return PickupAndDropOffService.update({ pickupId, serviceId, ticketId, ...pickUpDropOff }).then(() => {
      onClosePickupModal()
      updateReservation()
    }).finally(() => setLoading(false))
  }

  const createPickup = async (pickUpDropOff) => {
    setLoading(ticketIds)
    return PickupAndDropOffService.create({ reservationId, serviceId, ticketIds, ...pickUpDropOff }).then(() => {
      onClosePickupModal()
      updateReservation()
    }).finally(() => setLoading(false))
  }

  const getPickup = useCallback(async () => {
    return PickupAndDropOffService.get(reservationId).then(setPickupTickets)
  }, [reservationId])

  return {
    isPickupModalVisible,
    onClosePickupModal,
    onClickPickup,
    onUnlinkPickup,
    loading,
    editPickup,
    getPickup,
    pickupTickets,
    pickup,
    setPickup,
    onEditClickPickup,
    createPickup,
    onOpenAutoFillModal,
    onCloseAutoFillModal,
    isPickupCreationOrEdition,
    isPickupAutoFillModalVisible,
    ticketIds,
    serviceId
  }
}
