/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, colors, PlusIcon } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'

const container = css`
  display: flex;
  flex-direction: row;
  text-align: center;
  gap: .5rem;
  color: ${colors.primary};
  background-color: transparent;
  border: none;

  &:hover {
    opacity: .8;
    transition: opacity .1s ease-in;
  }
`

export const NewPickUpAddressBtn = ({ onClick = identity }) => {
  return (
    <Button css={container} onClick={onClick}>
      <PlusIcon /> Adicionar local de embarque
    </Button>
  )
}
