export const PickUpAddressParser = {
  single: (pickUpAddress = {}) => {
    const {
      id,
      name,
      company_id,
      country_id,
      state_id,
      city_id,
      district,
      latitude,
      longitude,
      post_code,
      street,
      supplement
    } = pickUpAddress

    return {
      id,
      name,
      companyId: company_id,
      countryId: country_id,
      stateId: state_id,
      cityId: city_id,
      district,
      latitude,
      longitude,
      postCode: post_code,
      street,
      supplement
    }
  },
  list: (data) => {
    const { pick_up_addresses = [], meta } = data

    return {
      pickUpAddresses: pick_up_addresses.map(PickUpAddressParser.single),
      meta: {
        currentPage: meta.current_page,
        totalEntries: meta.total_entries,
        totalPages: meta.total_pages
      }
    }
  }

}
