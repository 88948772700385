/* eslint-disable camelcase */
import { parseAttribute, getImageUrl } from 'services/Orb/Service'
import { deepGetOrElse } from 'deep-getter'
import { formatISOTime } from 'utils/time'
import moment from 'moment'
import { head, tail, unmaskPhone } from '@bonitour/common-functions'
import { ACTIVITY_TYPE, TRANSPORT_TYPE } from 'constants/activityTypes'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { sellingChannelOptions } from 'domains/CompanyReports/Sales/constants/sellingChannel'

dayjs.extend(utc)

const reservationListItem = (reservation = {}) => {
  const {
    id,
    reservation_code: reservationCode = '',
    payer = {},
    status = '',
    commercial_partner: commercialPartner
  } = reservation

  const { email = '', full_name: fullName = '', additional_information: additionalInformation } = payer || {}
  const { name: partnerName = '' } = commercialPartner || {}

  return {
    id,
    code: reservationCode.toUpperCase(),
    email,
    operadora: partnerName,
    name: fullName,
    status,
    additionalInformation
  }
}

export const reservationListParser = ({ meta = {}, data = [] }) => {
  const { total_pages: totalPages = 0, total_entries: total = 0 } = meta
  const reservations = data.map(parseAttribute(reservationListItem))
  return { reservations, totalPages, total }
}

function parseReservationFinancialDetails (attributes = {}) {
  const number = deepGetOrElse(attributes, 'payer.telephone', '')
  const phone = unmaskPhone(number || '')
  const phoneNumberWithDDI = phone?.length <= 10 ? `55${phone}` : phone

  return {
    id: attributes.id,
    payerId: deepGetOrElse(attributes, 'payer.id', ''),
    payerUserId: deepGetOrElse(attributes, 'payer.user_id', ''),
    name: deepGetOrElse(attributes, 'payer.full_name', ''),
    email: deepGetOrElse(attributes, 'payer.email', ''),
    birthDate: deepGetOrElse(attributes, 'payer.birth_date', new Date()),
    identificationDocument: deepGetOrElse(attributes, 'payer.document', ''),
    number: phoneNumberWithDDI
  }
}

function parseExtraInfos (extraInfos = {}) {
  const { eko_locator = '' } = extraInfos

  return { ekoLocator: eko_locator }
}

function parseReservationInfo (attributes = {}, reservationCode) {
  const birthDate = deepGetOrElse(attributes, 'payer.birth_date', undefined)
  const { status, created_at, comment, selling_channel, extra_infos, affiliate_code, fiscal_duty } = attributes
  const number = deepGetOrElse(attributes, 'payer.telephone', '')
  const phone = unmaskPhone(number || '')
  const phoneNumberWithDDI = phone?.length <= 10 ? `55${phone}` : phone
  const additionalInformation = ReservationParser.additionalInformation(
    deepGetOrElse(attributes, 'payer.additional_information', '')
  )

  const extraInfos = parseExtraInfos(extra_infos)

  const parsedSellingChannel = sellingChannelOptions.find(({ value }) => value === selling_channel)

  return {
    id: reservationCode,
    payerId: deepGetOrElse(attributes, 'payer.id', ''),
    payerUserId: deepGetOrElse(attributes, 'payer.user_id', ''),
    name: deepGetOrElse(attributes, 'payer.full_name', ''),
    email: deepGetOrElse(attributes, 'payer.email', ''),
    status,
    createdAt: created_at,
    accountableId: deepGetOrElse(attributes, 'commercial_partner.id', ''),
    accountable: deepGetOrElse(attributes, 'commercial_partner.name', ''),
    identificationDocument: deepGetOrElse(attributes, 'payer.document', ''),
    birthDate: birthDate && moment(birthDate),
    number: phoneNumberWithDDI,
    country: deepGetOrElse(attributes, 'payer.address.country_id', ''),
    state: deepGetOrElse(attributes, 'payer.address.state_id', ''),
    city: deepGetOrElse(attributes, 'payer.address.city_id', ''),
    street: deepGetOrElse(attributes, 'payer.address.street', ''),
    zipCode: deepGetOrElse(attributes, 'payer.address.post_code', ''),
    supplement: deepGetOrElse(attributes, 'payer.address.supplement', ''),
    district: deepGetOrElse(attributes, 'payer.address.region', ''),
    comments: comment,
    affiliateCode: affiliate_code,
    extraInfos,
    sellingChannel: parsedSellingChannel?.label,
    fiscalDuty: fiscal_duty,
    ...additionalInformation
  }
}

function parseVisualizedReservation ({
  id,
  reservation_code,
  checked_in,
  visualized_in_ally
}) {
  return {
    id,
    reservationCode: reservation_code,
    checkedIn: checked_in,
    visualizedInAlly: visualized_in_ally
  }
}

function parseVisualizedReservationMeta ({
  total_entries,
  current_page,
  total_pages
}) {
  return {
    totalEntries: total_entries,
    currentPage: current_page,
    totalPages: total_pages
  }
}

export const ReservationParser = {
  ticketSummary (tickets) {
    return tickets.reduce((acc, ticket) => {
      const { type } = ticket
      acc[type] = (acc[type] || 0) + 1
      return acc
    }, {})
  },
  additionalInformation (info) {
    const {
      emergency_telephone: emergencyTelephone,
      emergency_name: emergencyName,
      weight,
      height
    } = info

    return {
      emergencyTelephone,
      emergencyName,
      weight,
      height: String(height)
    }
  },
  ticket: (ticket) => {
    const { external_integrations = [] } = ticket

    return {
      id: ticket.id,
      date: ticket.slot,
      time: formatISOTime(ticket.slot, 'HH:mm', false),
      type: deepGetOrElse(ticket, 'description.fee.name', ''),
      externalCode: head(external_integrations)?.external_ticket_id,
      value: ticket.price,
      code: ticket.ticket_code,
      status: ticket.state,
      passengerName: ticket.passenger_name,
      hasIntegration: ticket.has_integration,
      externalIntegrations: external_integrations.map(ReservationParser.externalIntegrations),
      commercialPartnerId: ticket.commercial_partner_id,
      deadlineDate: ticket.deadline_date
    }
  },
  tickets (attributes = {}, reservationCode) {
    const slots = deepGetOrElse(attributes, 'activities', []).map(({ tickets = {} }) => deepGetOrElse(tickets, '0.slot', '').slice(0, 10)).sort((a, b) => a.localeCompare(b))
    return {
      id: reservationCode,
      status: attributes.status,
      firstDate: head(slots),
      partnerId: deepGetOrElse(attributes, 'commercial_partner_id', ''),
      partner: deepGetOrElse(attributes, 'commercial_partner.name', ''),
      activities: deepGetOrElse(attributes, 'activities', []).map(
        ({
          id = '',
          image = '',
          activity_name: activityName = '',
          company_name: companyName = '',
          tickets: ticketList = []
        }) => {
          const tickets = ticketList.map(ReservationParser.ticket).sort((a, b) => (a.code > b.code ? 1 : -1))
          const ticketSummary = ReservationParser.ticketSummary(tickets)

          return {
            activityId: id,
            activityName,
            partnerId: deepGetOrElse(ticketList, '0.company_id', ''),
            activityImage: getImageUrl(image),
            tickets,
            ticketSummary,
            companyName
          }
        })
    }
  },
  instance ({ attributes = {} }) {
    const { reservation_code: reservationCode = '', commercial_partner_id: partnerId, id, fiscal_duty } = attributes
    const info = parseReservationInfo(attributes, reservationCode)
    const finance = parseReservationFinancialDetails(attributes)
    const tickets = ReservationParser.tickets(attributes, reservationCode)
    return {
      id,
      partnerId,
      info,
      finance,
      tickets,
      reservationCode,
      activities: deepGetOrElse(attributes, 'activities', []).map(
        ({
          id = '',
          image = '',
          activity_name: activityName = '',
          company_name: companyName = ''
        }) => {
          return {
            activityId: id,
            activityName,
            activityImage: getImageUrl(image),
            companyName
          }
        }),
      fiscalDuty: fiscal_duty
    }
  },
  externalIntegrations (externalIntegration = {}) {
    const {
      id,
      integration_name: integrationName,
      external_ticket_id: externalTicketId,
      external_status: externalStatus,
      extra_data: extraData,
      response
    } = externalIntegration

    return {
      id,
      integrationName,
      externalTicketId,
      externalStatus,
      extraData,
      response
    }
  },
  reservationTickets (reservartionSummary) {
    return reservartionSummary.map(ReservationParser.summarySingle)
  },
  summarySingle (reservation) {
    const reservationDate = head(reservation)
    const reservationData = Object.entries(tail(reservation)).map(data => ReservationParser.reservationData(tail(data), head(data))).flat()
    return [reservationDate, reservationData]
  },
  pickupPlaceData (ticket) {
    const findExtraData = (key) =>
      ticket.externalIntegrations.find(({ extraData } = {}) => extraData?.[key])?.extraData?.[key]
    return {
      id: findExtraData('local_embarque'),
      name: findExtraData('nome_local_embarque')
    }
  },
  reservationData (reservationData, serviceId) {
    const {
      canceled_tickets,
      image,
      non_canceled_tickets,
      pax,
      title,
      vendor_name,
      service_type
    } = reservationData

    const tickets = [...non_canceled_tickets.map((ticket) => ReservationParser.ticketData(ticket, service_type)), ...canceled_tickets.map((ticket) => ReservationParser.ticketData(ticket, service_type))]
    const ticketsByPickupId = tickets.reduce((acc, ticket) => {
      const pickupPlace = this.pickupPlaceData(ticket)
      const pickupKey = JSON.stringify(pickupPlace)
      return {
        ...acc,
        [pickupKey]: [
          ...(acc[pickupKey] || []),
          {
            ...ticket,
            pickupPlace
          }
        ]
      }
    }, {})

    const recalculatePax = (tickets, pax) =>
      Object.fromEntries(
        Object.entries(pax).map(
          ([paxKey, _value]) => [
            paxKey,
            tickets.filter(({ type }) => (type || 'offline') === paxKey).length
          ]
        ).filter(([_key, value]) => value > 0)
      )

    const recalculateTotalAmount = tickets => tickets.reduce((acc, { price }) => acc + Number(price), 0)

    return Object.entries(ticketsByPickupId).map(([pickup, tickets]) => ({
      tickets,
      image,
      pax: recalculatePax(tickets, pax),
      title,
      serviceId,
      type: service_type,
      totalAmount: recalculateTotalAmount(tickets),
      vendorName: vendor_name,
      pickupPlace: JSON.parse(pickup)
    }))
  },
  ticketData (ticketData, ticketType) {
    const {
      commercial_partner_id,
      external_integrations,
      has_integration,
      company_id,
      id,
      passenger,
      price,
      slot,
      state,
      ticket_code,
      service_id,
      observation,
      deadline_date,
      description,
      experience
    } = ticketData

    const {
      dropoff_date: dropOffDate,
      dropoff_hour: dropOffHour,
      pickup_date: pickUpDate,
      pickup_hour: pickUpHour,
      checkout_date: checkoutDate
    } = description

    const ticketDates = (function () {
      const DATE_FORMAT = 'DD/MM/YYYY'
      const HOUR_FORMAT = 'HH:mm'

      const toFormat = (format) => (iso) => iso ? dayjs(iso).utc().format(format) : ''
      const isoToDate = toFormat(DATE_FORMAT)
      const isoToHour = toFormat(HOUR_FORMAT)

      const isDefaultDateTime = ticketType === ACTIVITY_TYPE || ticketType === TRANSPORT_TYPE

      if (isDefaultDateTime) {
        return {
          startDate: isoToDate(slot),
          startHour: isoToHour(slot),
          endDate: '',
          endHour: ''
        }
      } else {
        return {
          startDate: isoToDate(pickUpDate || slot),
          startHour: pickUpHour || isoToHour(slot),
          endDate: isoToDate(dropOffDate || checkoutDate),
          endHour: dropOffHour || (isoToHour(checkoutDate))
        }
      }
    })()

    return {
      commercialPartnerId: commercial_partner_id,
      hasIntegration: has_integration,
      companyId: company_id,
      id,
      passenger: passenger ? ReservationParser.passenger(passenger) : null,
      price,
      slot,
      state,
      ticketCode: ticket_code,
      externalIntegrations: external_integrations.map(ReservationParser.externalIntegrations),
      serviceId: service_id,
      experienceId: experience?.id,
      type: deepGetOrElse(ticketData, 'description.fee.name', ''),
      capacity: deepGetOrElse(ticketData, 'description.fee.description.capacity', ''),
      observation,
      ...(deadline_date ? { deadlineDate: deadline_date } : {}),
      ticketDates
    }
  },
  passenger (passengerData) {
    const {
      id,
      full_name,
      email,
      birth_date,
      document,
      telephone,
      user_id,
      created_at,
      update_at,
      address_id,
      address
    } = passengerData

    return {
      id,
      passengerName: full_name,
      email,
      birthDate: birth_date,
      document,
      telephone,
      userId: user_id,
      createdAt: created_at,
      updateAt: update_at,
      addressId: address_id,
      address
    }
  },
  financialTransactionsTotalizers ({ reservation_financial_transaction_totalizers }) {
    const {
      total_amount,
      total_amount_paid,
      total_amount_due,
      total_cancellation_balance,
      sent_links_by_ticket
    } = reservation_financial_transaction_totalizers

    return {
      totalAmount: Number(total_amount),
      totalAmountPaid: Number(total_amount_paid),
      totalAmountDue: Number(total_amount_due),
      totalCancellationBalance: Number(total_cancellation_balance),
      sentLinksByTicket: sent_links_by_ticket
    }
  },
  extraInfos: parseExtraInfos,
  visualizedReservationsReport ({ meta, reservations }) {
    const parsedReservations = reservations.map(parseVisualizedReservation)
    const parsedMeta = parseVisualizedReservationMeta(meta)
    return {
      meta: parsedMeta,
      reservations: parsedReservations
    }
  }
}
