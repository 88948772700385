import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const container = css`
  display: flex;
  margin: 0;
`
export const link = css`
  text-decoration: none;
`

export const tabPaneContainer = css`
  width: 100%;
  padding: 0.25rem 0 2rem;
`

export const underlineNavContainer = css`
  padding: 0;
`

export const pickupAddressesCard = css`
  color: ${colors.gray3};
  margin-right: 0;

  label {
    min-width: 9rem;

    @media (max-width: ${BREAK_POINTS.tableUp}) {
      min-width: unset;
    }
  }

  svg {
    font-size: 2rem;
  }

  &:hover {
    > * {
      cursor: pointer;
      color: ${colors.gray4};
      transition: all .1s ease-in;
    }
  }
`
