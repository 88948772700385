/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { NavLink } from 'react-router-dom'
import { CalendarIcon, CogIcon, colors, LightEmptyResult, Label, ChartsIcon, PlusIcon, ModifyPriceIcon } from '@bonitour/components'
import { hidden, marginTop, resetLink, skeletonCardEffect } from 'assets/styles/global'
import { CardButton } from 'components/CardButton'
import { ActivitySelector } from 'containers/Activity/Selector/Selector'
import { useActivity } from 'contexts/Activity'
import { useMemo } from 'react'
import { useRouteByType } from 'hooks/useRouteByType'
import { COMBINED_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE, TRANSPORT_TYPE, LIMBER_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { BookingMapOperationProgress } from 'components/BookingMap/BookingMapOperationProgress'
import { ServiceCombinedExperienceList } from 'domains/CombinedExperience/components'
import { usePermission } from 'contexts/Permissions'
import { useFeatureFlag } from 'contexts/Feature'

const container = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const inlineFlex = css`
  display: inline-flex;
`

const iconSize = css`
  font-size: 24px;
`

const cardButtonColor = css`
  color: ${colors.gray4};
`

const labelCardButton = css`
  width: 80px;
  cursor: pointer;
  ${cardButtonColor};
  text-align: center;
  margin: 2px 0;
`

const emptyContainer = css`
  margin: 25px;
`

const cardButton = css`
  margin: 10px;
  padding: 5px 10px;
  min-height: 65px;
`

const marginBottom20 = css`
  margin-bottom: 20px;
`

const alertPaddingRightStyle = css`
  padding-right: 1rem;
`

const buttonSkeleton = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.50rem;
  .icon {
    ${skeletonCardEffect}
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 4px;
  }
  .label {
    ${skeletonCardEffect}
    height: 0.75rem;
    width: 4rem;
  }
`

const skeletonContainer = css`
  ${inlineFlex}
  * {
    cursor: default;
  }
`

export const ActivityDashboard = () => {
  const {
    id: activityId,
    activity: { isPartner = false, type, experiences },
    isLoadingActivityFetch
  } = useActivity()

  const canShowSkeleton = useMemo(() => isLoadingActivityFetch || !type, [isLoadingActivityFetch, type])

  const {
    isOffline,
    isCombined,
    _isTransport,
    _isLimber
  } = useMemo(() => ({
    isOffline: type === OFFLINE_EXPERIENCE_TYPE,
    isCombined: type === COMBINED_EXPERIENCE_TYPE,
    isTransport: type === TRANSPORT_TYPE,
    isLimber: type === LIMBER_EXPERIENCE_TYPE
  }), [type])

  const { allowed: canCreatePriceModifier } = usePermission({
    permission: 'price_modifier',
    action: 'create'
  })

  const { allowed: canUpdatePriceModifier } = usePermission({
    permission: 'price_modifier',
    action: 'update'
  })

  const [enablePriceModifier] = useFeatureFlag('orb-price-modifiers')

  const { basePath } = useRouteByType(`${activityId}/dashboard`)

  return (
    <>
      <div css={marginBottom20}>
        <ActivitySelector subdomain='dashboard'/>
        <BookingMapOperationProgress
          customCss={[marginTop(15), alertPaddingRightStyle]}
        />
      </div>
      <div css={container}>
        <div css={inlineFlex}>
          {isCombined && (
            <ServiceCombinedExperienceList
              experiences={experiences}
            />
          )}
        </div>
        <div css={canShowSkeleton ? hidden : inlineFlex}>
          {!isOffline
            ? (
              <NavLink to={`${basePath}/bookings`} css={[resetLink]}>
                <CardButton css={cardButton}>
                  <CalendarIcon css={[iconSize, cardButtonColor]} />
                  <Label css={labelCardButton}>Mapa de Vagas</Label>
                </CardButton>
              </NavLink>
            )
            : (
              <NavLink to={`${basePath}/bookings/create`} css={[resetLink]}>
                <CardButton css={cardButton}>
                  <PlusIcon css={[iconSize, cardButtonColor]} />
                  <Label css={labelCardButton}>Nova reserva</Label>
                </CardButton>
              </NavLink>
            )}

          {!isCombined && (
            <NavLink to={`${basePath}/daily-monitoring`} css={[resetLink]}>
              <CardButton css={cardButton}>
                <ChartsIcon css={[iconSize, cardButtonColor]} />
                <Label css={labelCardButton}>Acompanhamento Diário</Label>
              </CardButton>
            </NavLink>
          )}

          {!isPartner && (
            <NavLink to={`${basePath}/edit`} css={[resetLink]}>
              <CardButton css={cardButton}>
                <CogIcon css={[iconSize, cardButtonColor]} />
                <Label css={labelCardButton}>Editar</Label>
              </CardButton>
            </NavLink>
          )}

          {!isCombined && !isOffline && canUpdatePriceModifier && canCreatePriceModifier && enablePriceModifier && (
            <NavLink to={`${basePath}/price-modifier`} css={[resetLink]}>
              <CardButton css={cardButton}>
                <ModifyPriceIcon css={[iconSize, cardButtonColor]} />
                <Label css={labelCardButton}>Modificar Tarifário</Label>
              </CardButton>
            </NavLink>
          )}

        </div>
        <div css={canShowSkeleton ? skeletonContainer : hidden}>
          {Array.from({ length: 5 }).fill(null).map((_, idx) => (
            <CardButton key={`dashboard__btn__${idx}`} css={cardButton}>
              <div css={buttonSkeleton}>
                <div className='icon' />
                <div className='label' />
              </div>
            </CardButton>
          ))}
        </div>
      </div>
      <div css={emptyContainer}>
        <LightEmptyResult title='Dashboard sem informações' subtitle='' />
      </div>
    </>
  )
}
