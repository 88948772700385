/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useHistory } from 'react-router-dom'

import { ConfirmDialog, HeaderPage, InputWithPrefix, PaginationBar, SearchIcon } from '@bonitour/components'
import { useCallback, useEffect, useState } from 'react'
import { PickupModal } from 'domains/Reservation/Pickup/PickupModal'
import { deletionDialog, deletionDialogContainer, paginationBar, topActionsContainer } from './PickUpAddressesPage.style'
import { PickUpAddressesList } from './PickUpAddressesList'
import { usePickUpAddresses } from 'hooks/domains/usePickUpAddresses'
import { NewPickUpAddressBtn } from './components/NewPickUpAddressButton'
import { useFeatureFlag } from 'contexts/Feature'

export const PickUpAddressesPage = () => {
  const [isAddingAddress, setIsAddingAddress] = useState(false)
  const [deleteDialogState, setDeleteDialog] = useState({
    isVisible: false,
    addressName: '',
    addressId: null
  })
  const { push } = useHistory()
  const backToDashboard = useCallback(
    () => push('/activities'),
    [push]
  )

  const {
    pickUpAddresses,
    pagination,
    isFetching,
    isLoading,
    onCreate: createPickUpAddress,
    onDelete: deletePickUpAddress,
    onRefetch: refetchPickUpAddresses,
    onPaginate: paginatePickUpAddresses,
    onChangeName
  } = usePickUpAddresses()

  const openDeleteDialog = useCallback((addressName, addressId) => {
    setDeleteDialog({
      isVisible: true,
      addressName,
      addressId
    })
  }, [])

  const onCloseDeleteDialog = useCallback(() => {
    setDeleteDialog({
      isVisible: false,
      addressName: '',
      addressId: null
    })
  }, [])

  const onDelete = useCallback(async () => {
    return await deletePickUpAddress({
      id: deleteDialogState.addressId,
      onSuccess: () => {
        onCloseDeleteDialog()
        refetchPickUpAddresses()
      }
    })
  }, [deleteDialogState, deletePickUpAddress, onCloseDeleteDialog, refetchPickUpAddresses])

  const onSubmitPickUpAddress = useCallback(async (pickUpAddress) => {
    await createPickUpAddress({
      data: pickUpAddress,
      onSuccess: () => {
        setIsAddingAddress(false)
        refetchPickUpAddresses()
      }
    })
  }, [createPickUpAddress, refetchPickUpAddresses])

  const onPagination = useCallback(({ page = pagination.page, perPage = pagination.perPage }) => {
    if (perPage !== pagination.perPage) {
      return paginatePickUpAddresses(1, perPage)
    }

    paginatePickUpAddresses(page, perPage)
  }, [paginatePickUpAddresses, pagination.page, pagination.perPage])

  const [isPickupPlacesEnabled, _, isFlagReady] = useFeatureFlag('orb-transport-pickup-selector')

  useEffect(() => {
    if (isFlagReady && !isPickupPlacesEnabled) {
      backToDashboard()
    }
  }, [isFlagReady, isPickupPlacesEnabled, backToDashboard])

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Locais de embarque' subtitle='Locais de embarque podem ser vinculados aos seus transportes' />

      <div css={topActionsContainer}>
        <InputWithPrefix
          placeholder='Filtre pelo nome'
          onChange={onChangeName}
          className='search_input'
        >
          <SearchIcon />
        </InputWithPrefix>

        <NewPickUpAddressBtn onClick={() => setIsAddingAddress(true)} />
      </div>

      <PickUpAddressesList
        pickUpAddresses={pickUpAddresses}
        onDelete={(name, id) => openDeleteDialog(name, id)}
        onAdd={() => setIsAddingAddress(true)}
        isLoading={isLoading || isFetching}
      />

      {pagination.total
        ? <PaginationBar
          currentPage={pagination.page}
          perPage={pagination.perPage}
          perPageOptions={[10, 25, 50, 100]}
          totalElements={pagination.total}
          totalPages={pagination.totalPages}
          customCss={paginationBar}
          onPageChange={(page) => onPagination({
            page
          })}
          onPerPageChange={(perPage) => onPagination({
            perPage
          })}
        />
        : null}

      <ConfirmDialog
        title={`Remover ${deleteDialogState.addressName}`}
        isVisible={deleteDialogState.isVisible}
        onClose={onCloseDeleteDialog}
        cancelCallback={onCloseDeleteDialog}
        successCallback={onDelete}
        customCss={[deletionDialog]}
        customContainercss={deletionDialogContainer}
        buttonLabelConfirm='Remover'
      >
        Tem certeza que gostaria de remover este local de embarque ?
      </ConfirmDialog>

      <PickupModal
        pickup={{}}
        onSuccess={onSubmitPickUpAddress}
        isPickupModalVisible={isAddingAddress}
        onClose={() => setIsAddingAddress(false)}
      />
    </>
  )
}
