
export const PickUpAddressModel = {
  listPayload: ({ name, serviceId, pagination }) => {
    const { page = 1, perPage = 10 } = pagination

    return {
      name,
      service_id: serviceId,
      page,
      per_page: perPage
    }
  },
  create: (pickUpAddress) => {
    const {
      name
    } = pickUpAddress

    const {
      city,
      state,
      country,
      zipCode,
      address: street,
      addressDetail: supplement,
      district,
      latLong = ''
    } = pickUpAddress?.addressInfo || {}

    const [latitude, longitude] = latLong.split(',')

    return {
      name,
      city_id: city,
      state_id: state,
      country_id: country,
      post_code: zipCode,
      street,
      supplement,
      district,
      latitude,
      longitude
    }
  }
}
