import { string } from 'yup'

export const PickupAddressSchema = () => {
  return ({
    zipCode: string().nullable(),
    address: string().nullable(),
    addressDetail: string().nullable(),
    district: string().nullable(),
    country: string().nullable(),
    state: string().nullable(),
    city: string().nullable(),
    latLong: string().matches(/^-?\d+(?:\.\d+)?\s*,\s*-?\d+(?:\.\d+)?$/, "Deve ser preenchido no seguinte formato 'Latitude, Longitude'").nullable()
  }
  )
}
