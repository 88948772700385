/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Dialog, flexColumn, Label, Row, Select } from '@bonitour/components'
import { inputGroup, marginTop7 } from './Pickup.style'
import { useEffect, useMemo } from 'react'
import { AutoFillPickUpModalContainer as container } from './AutoFillPickupModal.style'

export const AutoFillPickupModal = ({
  servicePickUpAddresses,
  isLoading,
  isVisible,
  onClose: emitCloseClick,
  onSelect = identity,
  onFillManually = identity
}) => {
  const options = useMemo(() => {
    return servicePickUpAddresses?.map(({ name, id }) => ({ label: name, value: id })) || []
  }, [servicePickUpAddresses])

  const isEmpty = useMemo(() => !servicePickUpAddresses?.length, [servicePickUpAddresses])

  useEffect(() => {
    // If there's no options, open address form automatically
    if (isEmpty && !isLoading && isVisible) {
      onFillManually()
    }
  }, [isEmpty, isLoading, isVisible, onFillManually])

  return (
    <Dialog
      customContainercss={[container]}
      title='Auto-preencher local de embarque'
      isVisible={isVisible}
      onClose={emitCloseClick}
    >
      <Row>
        <div css={[flexColumn, inputGroup, marginTop7]}>
          <Label htmlFor='name'>Local de embarque</Label>
          <Select
            placeholder='Selecione um local de embarque'
            onChange={selectedId => {
              const selectedAddress = servicePickUpAddresses?.find(({ id }) => id === selectedId)
              onSelect(selectedAddress)
            }}
            disabled={isLoading}
            options={options}
          />
        </div>
      </Row>

      <div className='fill_address__label'onClick={onFillManually}>Preencher manualmente</div>
    </Dialog>
  )
}
