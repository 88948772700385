/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/react'

import { Card, colors, Label, WarningIcon } from '@bonitour/components'

import { hidden } from 'assets/styles/global'

const labelStyle = css`
  text-align: left;
  font-size: 0.75rem;
  span {
    font-weight: normal;
  }
  i {
    margin-right: 0.25rem;
    color: ${colors.yellow1};
  }
`

const cardStyle = css`
  background-color: transparent;
  border-radius: 0 0 10px 10px;
  padding-top: 0.25rem;
  border-color: transparent;
`

export const ServiceWithoutVacancyWarn = ({
  hasVacancies = true
}) => {
  return (
    <Card padding={5} css={hasVacancies ? hidden : cardStyle}>
      <Label css={labelStyle}>
        <WarningIcon/>
        <span>
            Sem vagas disponíveis
        </span>
      </Label>
    </Card>
  )
}
