import { formatMoney } from '@bonitour/common-functions'
import { colorSequence } from 'utils/color-sequence'
import { parseAttribute } from 'services/Orb/Service'
import { parsePeriods } from 'core/parsers/periods'
import { convertDate } from 'utils/convertDate'
import { LIMBER_EXPERIENCE_TYPE } from 'constants/activityTypes'

function IntervalListParser (intervalList = []) {
  return intervalList.map(({ label: name, initial_date: initialDate, final_date: finalDate }) => ({
    name,
    initialDate: convertDate(initialDate),
    finalDate: convertDate(finalDate)
  }))
}

export function FeeListParser (feeList = []) {
  return feeList.map(({ id, attributes }, index) => ({
    id,
    color: colorSequence[index],
    name: attributes.name,
    intervalList: IntervalListParser(attributes.date_ranges),
    price: formatMoney(attributes.main_price),
    periodQuantity: (attributes.date_ranges && attributes.date_ranges.length) || 0
  }))
}

export function FeeTypesParser ({ feeTypes = [] }) {
  return feeTypes.reduce(
    (accumulator, { name, feeModificator = 0.1, minAge = 0, maxAge = 50 }) => ({
      ...accumulator,
      [name]: { feeModificator, minAge, maxAge }
    }),
    {}
  )
}

function PricesToTicketsParser ({ id, attributes: { name, description, fee_type_id: feeTypeId, pricing } = {} }) {
  const { min_age: minAge, max_age: maxAge } = description

  return {
    name,
    price: Number(pricing),
    fee_type_id: feeTypeId,
    description,
    fee_id: id,
    quantity: 0,
    minAge,
    maxAge
  }
}

function sortByPriorityNames (feeTypes) {
  const priorityNames = [
    'Privativo',
    'Adulto',
    'Criança',
    'Sênior',
    'Tarifa Única',
    'Meia',
    'Não pagante',
    'Cortesia',
    'Regional',
    'Estrangeiro'
  ]

  const filteredFeeTypes = feeTypes.filter((fee) =>
    fee?.attributes?.name && priorityNames.includes(fee.attributes.name)
  )

  const sortedFeeTypes = filteredFeeTypes.sort((a, b) =>
    priorityNames.indexOf(a?.attributes?.name) - priorityNames.indexOf(b?.attributes?.name)
  )

  return sortedFeeTypes
}

export const FeePricesToTickets = (type) => (feeTypes = []) => {
  if (type === LIMBER_EXPERIENCE_TYPE) {
    return feeTypes.map(PricesToTicketsParser)
  }
  const filteredFee = sortByPriorityNames(feeTypes)
  return filteredFee.map(PricesToTicketsParser)
}

const parseDemands = (demand = {}) => {
  const { id, percent_of_main_price: percent, sales_percent_trigger: salesTrigger } = demand

  const valueModificator = ((Number(percent) - 1) * 100)
  const operator = valueModificator > 0 ? 'increase' : 'decrease'
  return {
    id,
    limit: Number(salesTrigger) * 100,
    operator,
    valueModificator: Math.abs(valueModificator).toFixed(3)
  }
}

const parseFeeTypes = (feeType = {}) => {
  const { description: { min_age: minAge, max_age: maxAge, capacity } = {}, id, name, percent_of_main_price: percent, taxes = { guide: 0 } } = feeType
  const { guide, bearer } = taxes

  return {
    name,
    id,
    feeModificator: Math.abs(Number(percent) * 100).toFixed(3),
    minAge,
    maxAge,
    capacity,
    taxes: {
      guide: Math.abs(guide * 100).toFixed(3),
      bearer
    }
  }
}

const FeeParser = (fee = {}) => {
  const { name, main_price: mainPrice, date_ranges: dateRanges, demands = [], fee_types: feeTypes = [] } = fee
  const periodList = (dateRanges || []).map(parsePeriods)
  const feePaxTypes = (feeTypes || []).map(parseFeeTypes)
  const feesByDemands = (demands || []).map(parseDemands)

  return {
    feeBasic: {
      name,
      price: Number(mainPrice || 0)
    },
    periodList,
    feesByDemands,
    feePaxTypes
  }
}

export function parseFee (payload) {
  return parseAttribute(FeeParser)(payload)
}
