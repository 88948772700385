import { css } from '@emotion/core'
import { BREAK_POINTS, colors, iconHex } from '@bonitour/components'
import { skeletonCardEffect } from 'assets/styles/global'

const ADD_EXPERIENCE_BTN_BG_COLOR = colors.gray10

export const disabledEffect = css`
  opacity: 0.5;
  cursor: default;
  * {
    cursor: default;
  }
`

const serviceCardPadding = css`
  padding: 1.35rem;
`

export const dialogContainerStyle = css`
  width: 26.25rem;
  max-width: none;

  label {
    white-space: pre-wrap;
    word-break: break-word;
  }
`

export const dialogMarginBottom = css`
  margin-bottom: 1.5rem;
`

export const labelDialogStyle = css`
  text-align: left;
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  > b {
    font-weight: bold;
  }
`

export const serviceListStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: ${BREAK_POINTS.desktop}) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: ${BREAK_POINTS.smallTablet}) {
    grid-template-columns: 1fr;
  }

  &.empty-list {
    height: auto;
    display: flex;
    place-content: center;
    place-items: center;
    margin-top: 2.5rem;
    label {
      font-size: 1.10rem;
    }
  }
`

export const deleteButtonStyle = css`
  z-index: 1;
  display: flex;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  padding: 0.375rem;
  border-radius: var(--small-round-element-radius);
  transition: background-color 200ms;

  .icon {
    color: ${colors.red3};
    -webkit-text-stroke: 0.5px currentColor;
    transition: color 200ms;
  }

  .icon-label {
    display: inline-block;
    max-width: 0;
    overflow: hidden;
    font-size: 0.875rem;
    color: ${colors.white};
    padding: 0;
    opacity: 0;
    transition: max-width 200ms, opacity 200ms, padding 200ms;
  }

  &:hover {
    background-color: ${colors.red3};
    .icon {
      color: ${colors.white};
      -webkit-text-stroke: 0.5px ${colors.white};
    }
    .icon-label {
      max-width: 5rem;
      opacity: 1;
      padding-right: 0.375rem;
    }
  }
`

const addServiceBtnWithoutStyles = css`
  i, span {
    color: ${colors.gray4};
  }
  div, i, button {
    cursor: default;
    background-color: transparent;
    border: 0;
    box-shadow: none !important;
  }
`

const baseAddServiceBtnStyle = css`
  user-select: none;
  display: flex;
  width: auto;
  border: 0;
  margin: 0;
  padding: 0;
  padding-top: 1.50rem;
  margin-right: 0.4rem;
  > div {
    display: flex;
    place-content: center;
    place-items: center;
    cursor: pointer;
    > button {
      border-radius: 0.5rem;
      width: 2.15rem;
      height: 2.15rem;
      font-size: 1.50rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    > span {
      color: ${colors.indigo1};
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }
`

const addServiceBtnRowStyle = css`
  place-content: flex-start;
  cursor: default;
  background-color: transparent;

  > div > button {
    font-size: 1.10rem;
    width: 1.65rem;
    height: 1.65rem;
  }

  > div > span {
    font-size: 0.85rem;
  }

  &:hover {
    background-color: transparent;
  }
`

export const addServiceBtnLoadingStyle = css`
  ${baseAddServiceBtnStyle}
  ${disabledEffect}
  ${addServiceBtnRowStyle}
  > div, > div > button {
    cursor: default;
    box-shadow: none;
  }

  > div > button {
    i {
      animation: spin 3s ease-in-out infinite;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const addServiceBtnStyle = css`
  background-color: ${ADD_EXPERIENCE_BTN_BG_COLOR};
  transition-duration: 200ms;
  cursor: pointer;

  ${baseAddServiceBtnStyle}

  &:hover {
    background-color: ${colors.gray8};
  }

  &.on__bottom {
    display: flex;
    place-items: center;
    place-content: center;
    padding-top: 0;
    margin-right: 0;
    height: 8rem;
    > div {
      padding: 1.15rem;
    }
  }

  &.row {
    ${addServiceBtnRowStyle}
  }

  &.is__read_only_not_as_row {
    cursor: default;
    ${addServiceBtnWithoutStyles}
    > div {
      display: flex;
      flex-direction: column;
      > span {
        text-align: center;
        margin-top: 0.5rem;
      }
    }
    &:hover { background-color: ${ADD_EXPERIENCE_BTN_BG_COLOR}; }
  }

  &.is__read_only_as_row {
    ${addServiceBtnWithoutStyles}
    > div > button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > div > span {
      margin: 0;
    }
    i, span {
      font-size: 0.85rem;
      font-weight: bold;
    }
  }

  &.fab__active {
    > div > button {
      outline: 0;
      box-shadow: none;
    }
  }

  &.disabled__not_as_row {
    ${disabledEffect}
    &:hover { background-color: ${ADD_EXPERIENCE_BTN_BG_COLOR}; }
  }

  &.disabled__as_row {
    ${disabledEffect}
  }
`

export const serviceCardStyle = css`
  position: relative;
  ${serviceCardPadding}

  &.is-empty {
    animation: fadeInFromLeft 0.2s ease-in-out;
  }

  &.danger-border {
    border: 1px solid ${colors.red3};
  }

  > div:last-of-type {
    display: flex;
  }

  @keyframes fadeInFromLeft {
    from {
      margin-left: -1rem;
      opacity: 0;
    }
    to {
      margin-left: 0;
      opacity: 1;
    }
  }
`

export const serviceProfileStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;

  .discount__container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 0.5rem;
    
    label {
      font-weight: normal;
      color: ${colors.gray4};
      padding-top: 5px;
      margin: 0;
    }

    div,
    input {
      border: none;
      border-radius: unset;
      color: ${colors.gray4};
    }
    
    > div {
      border-bottom: 1px solid ${colors.gray7};
    }
    
    div div {
      padding: 0.25rem;
    }

    input {
      max-width: 60px;
      padding: 0.25rem;
      padding-right: 0;
      outline: none;
      text-align: center;
    }
  }

  > div {
    margin-right: 1rem;
  }

  > section {
    position: relative;
    flex-direction: column;
    justify-content: center;
  }
`

export const serviceTitleAndCompanyStyle = css`
  margin: 0;
  padding: 0;
  width: 100%;
  transform: scale(1.1);
  transform-origin: top left;
  margin-bottom: 0.5rem;
  cursor: default;

  > div {
    max-width: calc(100% - 2rem);
  }

  &.warning div:not(:first-of-type) {
    &, & > p {
      color: ${colors.orange6};
    }
    &::after {
      content: '\\${iconHex('warning')}';
      font-family: 'bonifont';
      margin-left: 0.25rem;
    }
  }
`

export const editButtonStyle = css`
  ${deleteButtonStyle}
  position: absolute;
  top: 0.75rem;
  right: -1.125rem;
  cursor: pointer;

  .icon {
    color: ${colors.primary};
  }

  &:hover {
    background-color: ${colors.indigo2};
  }
`

export const serviceCheckboxStyle = css`
  margin-left: 0;
  margin-right: 0;
  transform: scale(0.9);
  transform-origin: center left;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${colors.gray4};
`

export const inputFormGroupTooltipStyle = css`
  [class*='ErrorTooltip-errorTooltip-InputFormGroup'] {
    bottom: calc(100% - 1.55rem);
  }
`

export const checkboxStyle = css`
  margin: 2rem 0 2rem 0;
`

export const relativeStyle = css`
  position: relative;
  padding-top: 0.15rem;
`

export const floatContainerStyle = css`
  position: absolute;
  right: 0;
`

export const serviceCardSkeleton = css`
  ${serviceCardPadding}
  ${skeletonCardEffect}
`

export const combinedExperiencePartnerWarning = css`
  color: ${colors.orange6};
  background-color: ${colors.yellow3};
  border-color: ${colors.orange4};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 54rem;
  gap: 1rem;
  padding: 1rem;
  font-weight: 500;
  font-size: 0.9375rem;
  margin: 1.25rem auto -0.5rem;
  i {
    font-size: 1.25rem;
  }
  p {
    margin-bottom: 0.25rem;
  }
  b {
    font-weight: 700;
  }
  em {
    font-style: italic;
    font-weight: 400;
    opacity: 0.875;
  }
`

export const arrayInputWrapperStyle = css`
  > div {
    padding: 15px;
    padding-bottom: 50px;
    position: relative;

    background-color: ${colors.gray13};
    border-color: ${colors.gray7};
    margin-bottom: 10px;

    &:nth-of-type(2n) {
      background-color: ${colors.gray12};
    }

    transition: background-color 200ms, border-color 200ms;

    .remove_fee__btn {
      color: ${colors.red1} !important;
      border-color: currentColor;
      position: absolute;
      bottom: 10px;
      right: 15px;
      font-size: 14px;
      font-weight: 500;
      padding: 5px 10px;
      i {
        margin-left: 5px;
      }
    }
    &:has(.remove_fee__btn:hover) {
      border-color: ${colors.red1};
      background-color: rgba(255, 0, 0, 0.05);
    }
  }
`

export const descriptionAreaStyle = css`
  min-height: 65px;
  height: 65px;
  max-height: 150px;
`

export const feeByServiceWrapperStyle = css`
  display: flex;
  flex-wrap: wrap;
  background-color: ${colors.gray15};
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${colors.gray10};
  gap: 10px;
`

export const feeByServiceCardStyle = css`
  padding: 0 10px 10px;
  width: 243px;
`

export const pickupPlaceByServiceWrapperStyle = css`
  ${feeByServiceWrapperStyle}
  width: 100%;
`

export const pickupPlaceByServiceCardStyle = css`
  ${feeByServiceCardStyle}
  width: 251px;
  cursor: pointer;

  & * {
    cursor: pointer;
  }
`

export const pickupPlaceCardStyle = css`
  ${feeByServiceCardStyle}
  width: 100%;
  box-sizing: border-box;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`

export const selectStyle = css`
  width: 100%;
  padding: 8px 10px;
  font-family: inherit;
  border-radius: 10px;
  border: 1px solid ${colors.gray10};
  background-color: ${colors.white};
  color: ${colors.gray1};
`

export const toggleGroupStyle = css`
  flex-direction: row-reverse;
  margin: 10px 0 0;
  label {
    font-weight: 600;
  }
`

export const pickupOptionsListWrapperStyle = css`
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  margin: 0 -10px;
  padding: 0 10px;
`

export const pickupDialogContainerStyle = css`
  /* width: 26.25rem; */
  max-width: 35rem;
`

export const pickupPlaceFieldsWrapperStyle = css`
  max-height: 18rem;
  overflow-y: hidden;
  transition: max-height 0.3s ease-in-out, margin-bottom 0.3s ease-in-out;
  margin-bottom: -14px;
  
  &.disabled {
    max-height: 0;
    margin-bottom: 0;
  }
`

export const pickupPlaceCountStyle = css`
  display: flex;
  flex-direction: row;
  margin: -6px 0 6px 10px;
  gap: 6px;
  align-items: center;
  color: ${colors.gray2};
  height: 1.75rem;

  p {
    font-size: 0.875rem;
    font-weight: 500;

    strong {
      font-weight: 600;
    }

    &.active {
      color: ${colors.indigo4};
    }
    &.inactive {
      color: ${colors.red2};
    }
    &.active + p.inactive {
      color: ${colors.orange6};
    }
  }
`

export const noPickupPlaceLabelStyle = css`
  color: ${colors.gray2};
  font-weight: 500;
  line-height: 1.5;
`
