import { useCallback, useState, useEffect, useMemo } from 'react'
import { ZipCodeService } from 'services/ZipCode/Service'
import { StateApi } from 'services/Localities/StateApi'
import { CityApi } from 'services/Localities/CityApi'
import { CountryApi } from 'services/Localities/CountryApi'
import { CompanyService } from 'core/services/Company'
import { ExternalProviderService } from 'core/services/ExternalProvider'
import { useToast } from '@bonitour/components'
import { GeocodeApi } from 'services/Geocode/API'

const invalidAddressMessage = addToast => addToast('Não foi possível buscar a latitude e longitude, preencha corretamente o endereço')

export const useCompanyAddress = (addToast) => {
  const { add: addToastFallback } = useToast()
  const [companyAddress, setCompanyAddress] = useState({})
  const updateCompanyAddress = useCallback(({ offlineProviderId } = {}) => {
    const addressPromise = offlineProviderId ? ExternalProviderService.getById(offlineProviderId) : CompanyService.get()
    const addToastFn = addToast || addToastFallback
    addressPromise
      .then(({ addressInfo }) => {
        setCompanyAddress(addressInfo)
        addToastFn('Informações de endereço obtidas', 'success')
      }).catch(() => {
        addToastFn('Informações de endereço falharam ao serem obtidas')
      })
  }, [addToast, addToastFallback])
  return [companyAddress, updateCompanyAddress]
}

export const useZipCode = addToast => {
  const [zipCode, setZipCode] = useState({})
  const onZipCodeUpdate = useCallback(
    zipCode => {
      if (zipCode) {
        ZipCodeService.get(zipCode)
          .then(setZipCode)
          .catch(() => {
            addToast('CEP inválido')
          })
      }
    },
    [addToast]
  )
  return [zipCode, onZipCodeUpdate]
}

export const useGeoCode = addToast => {
  const [latLng, setLatLng] = useState({})

  const onLatLngUpdate = useCallback(
    addressParam => {
      if (addressParam.replace(/\s/g, '')) {
        GeocodeApi.get(addressParam)
          .then(setLatLng)
          .catch(() => {
            invalidAddressMessage(addToast)
            setLatLng({})
          })
      } else {
        invalidAddressMessage(addToast)
        setLatLng({})
      }
    },
    [addToast]
  )
  return [latLng, onLatLngUpdate]
}

export const useAddressLocation = (addToast, defaultCountry = '', defaultState = '') => {
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  const updateAvailableStates = useCallback(
    selectedCountry => {
      if (!selectedCountry) {
        return
      }
      StateApi.list(selectedCountry)
        .then(states => {
          setStates(states)
          setCities([])
        })
        .catch(() => {
          setCities([])
          setStates([])
          addToast('Ocorreu um erro na busca pelo estado')
        })
    },
    [addToast]
  )

  const updateAvailableCities = useCallback(
    (selectedCountry, selectedState) => {
      if (!selectedCountry || !selectedState) {
        return
      }
      CityApi.list(selectedCountry, selectedState)
        .then(setCities)
        .catch(() => {
          setCities([])
          addToast('Ocorreu um erro na busca pela cidade')
        })
    },
    [addToast]
  )

  useEffect(() => {
    CountryApi.list().then(setCountries)
  }, [])

  useEffect(() => {
    if (defaultCountry && defaultState) {
      updateAvailableStates(defaultCountry)
      updateAvailableCities(defaultCountry, defaultState)
    }
  }, [defaultCountry, defaultState, updateAvailableStates, updateAvailableCities])

  return {
    countries,
    states,
    cities,
    updateAvailableStates,
    updateAvailableCities
  }
}

export const useReadableAddress = ({ street, supplement: number, district, city, state, country, zipCode }) => {
  const { add: addToast } = useToast()
  const {
    countries,
    states,
    cities,
    updateAvailableStates,
    updateAvailableCities
  } = useAddressLocation(addToast)

  useEffect(() => { updateAvailableStates(country) }, [country, updateAvailableStates])
  useEffect(() => { updateAvailableCities(country, state) }, [country, state, updateAvailableCities])

  const countryName = useMemo(
    () => countries?.find(({ value }) => value === country)?.label,
    [countries, country]
  )

  const stateCode = useMemo(
    () => states?.find(({ value }) => value === state)?.initials,
    [state, states]
  )

  const cityName = useMemo(
    () => cities?.find(({ value }) => value === city)?.label,
    [city, cities]
  )

  return {
    countryName, stateCode, cityName, street, number, district, zipCode,
    longString: `${
      street || ''
    }${
      number ? `, ${number}` : ''
    }${
      district ? ` - ${district}` : ''
    }${
      cityName ? `, ${cityName}` : ''
    }${
      stateCode ? ` - ${stateCode}` : ''
    }${
      zipCode ? `, ${zipCode}` : ''
    }`
  }
}
