import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { ReservationService } from 'core/services/Reservation'
import { useReservation } from 'hooks/context/Reservation'
import { useToast } from '@bonitour/components'

export const TicketEditContext = createContext({})

export const useTicketEdit = () => useContext(TicketEditContext)

export const TicketEditProvider = ({ children }) => {
  const { reservationId, fetchReservationTickets } = useReservation()
  const { add: addToast } = useToast()
  const [isTicketEditModalVisible, setTicketEditModalVisible] = useState(false)
  const [ticketList, setTicketList] = useState([])
  const [editServiceId, setEditServiceId] = useState()
  const [editExperienceId, setEditExperienceId] = useState()

  const openEditionModal = useCallback((serviceId = '', tickets = [], experienceId = '') => {
    setEditExperienceId(experienceId)
    setTicketList(tickets)
    setEditServiceId(serviceId)
    setTicketEditModalVisible(true)
  }, [])

  const closeEditionModal = useCallback(() => {
    setTicketList([])
    setEditServiceId()
    setEditExperienceId()
    setTicketEditModalVisible(false)
  }, [])

  const onEditionSubmitted = useCallback((hour, day, returnPromise = false) => {
    const changeTicketSlotPromise = ReservationService
      .changeTicketSlot(reservationId, { activityId: editServiceId, experienceId: editExperienceId, hour, day, ticketsIds: ticketList })
      .then(() => {
        closeEditionModal()
        addToast('Reserva editada com sucesso', 'success')
        fetchReservationTickets()
      })
      .catch(({ data: { errors_msg = [] } }) => {
        const errorFilter = (msg) => msg === 'pricelist::update_failure::failure'
        if (errors_msg.some(errorFilter)) {
          addToast('A substituição de data deve ser para o mesmo tipo de tarifário.')
        } else {
          addToast('Houve um erro ao editar a reserva.')
        }
        closeEditionModal()
      })
    if (returnPromise) return changeTicketSlotPromise
  }, [reservationId, editServiceId, editExperienceId, ticketList, closeEditionModal, addToast, fetchReservationTickets])

  const contextData = useMemo(() => ({
    isTicketEditModalVisible,
    ticketList,
    editServiceId,
    editExperienceId,
    openEditionModal,
    closeEditionModal,
    onEditionSubmitted
  }), [isTicketEditModalVisible, ticketList, editServiceId, editExperienceId, openEditionModal, closeEditionModal, onEditionSubmitted])

  return (
    <TicketEditContext.Provider value={contextData}>
      {children}
    </TicketEditContext.Provider>
  )
}
