import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const AutoFillPickUpModalContainer = css`
  .fill_address__label {
    margin-top: 2rem;
    text-align: center;
    color: ${colors.primary};
    font-weight: 600;
    transition: all .2s ease-in;
    cursor: pointer;

    &:hover {
      color: ${colors.primaryVariant};
    }
  }
`
