/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { NavLink } from 'react-router-dom'
import { useMemo } from 'react'
import { jsx, css } from '@emotion/core'
import { AngleThickRightIcon, colors, Column, flexRow, P, Row } from '@bonitour/components'

import { resetLink } from 'assets/styles/global'
import { ExperienceListSelector } from 'containers/Activity/ListSelector/ListSelector'
import { EXTERNAL_EXPERIENCE_TYPES, INTERNAL_EXPERIENCE_TYPES } from 'constants/activityTypes'
import { useSellableList } from 'contexts/Activity'

const { primary, gray5 } = colors

const myActivities = css`
  font-size: 14px;
  color: ${gray5};
  margin-bottom: 10px;
`

const activitiesContainer = css`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`

const seeMoreIconPosition = css`
  transform: translate3d(5px,-7px,10px);
  color: ${primary};
`

const seeMoreText = css`
  color: ${primary};
`

const container = css`
  display: flex;
  width: 100%;
  max-height: 7.5rem;
  overflow: hidden;
  margin: -1.25rem 0 0.25rem;
`

export const ActivityList = () => {
  const { hasIntegrations } = useSellableList()
  const hasAnyIntegration = useMemo(() => Object.values(hasIntegrations).some(Boolean), [hasIntegrations])

  return (
    <>
      <Row>
        <Column phone={12} css={activitiesContainer}>
          <p css={myActivities}>Minhas experiências</p>
          <NavLink to='/activities?selectedNav=myExperiences' css={[flexRow, resetLink]}>
            <P css={seeMoreText}>Ver todas</P>
            <AngleThickRightIcon css={seeMoreIconPosition} />
          </NavLink>
        </Column>

        <Column phone={12}>
          <ExperienceListSelector
            subdomain='dashboard'
            fromCurrentCompany={true}
            smallCards
            disableScroll
            hideFilter
            css={container}
            onlyFirstPage
            disabledTypes={EXTERNAL_EXPERIENCE_TYPES}
          />
        </Column>
      </Row>

      <Row>
        <Column phone={12} css={activitiesContainer}>
          <p css={myActivities}>Experiências de parceiros</p>
          <NavLink to='/activities?selectedNav=partnerExperiences' css={[flexRow, resetLink]}>
            <P css={seeMoreText}>Ver todas</P>
            <AngleThickRightIcon css={seeMoreIconPosition} />
          </NavLink>
        </Column>

        <Column phone={12}>
          <ExperienceListSelector
            subdomain='dashboard'
            fromCurrentCompany={false}
            smallCards
            disableScroll
            hideFilter
            css={container}
            disabledTypes={EXTERNAL_EXPERIENCE_TYPES}
            onlyFirstPage
          />
        </Column>
      </Row>

      <Row>
        <Column phone={12} css={activitiesContainer}>
          <p css={myActivities}>Experiências {hasAnyIntegration ? 'externas' : 'offline'}</p>
          <NavLink to='/activities?selectedNav=externalExperiences' css={[flexRow, resetLink]}>
            <P css={seeMoreText}>Ver todas</P>
            <AngleThickRightIcon css={seeMoreIconPosition} />
          </NavLink>
        </Column>

        <Column phone={12}>
          <ExperienceListSelector
            subdomain='dashboard'
            smallCards
            disableScroll
            hideFilter
            css={container}
            disabledTypes={INTERNAL_EXPERIENCE_TYPES}
            onlyFirstPage
          />
        </Column>
      </Row>
    </>
  )
}
