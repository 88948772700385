/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useCallback } from 'react'
import { useForm } from '@bonitour/app-functions'
import { pickupSchema } from '../Pickup.schema'
import { useAddressLocation, useGeoCode, useZipCode } from 'containers/Address/Address.hooks'

const getLocalityName = (options, key) =>
  options.filter((item) => item.value === key).reduce((acc, item) => acc + item.label, '')

export const usePickupForm = ({ pickup, addToast, onSuccess }) => {
  const { addressInfo: { country, state, city } = {} } = pickup ?? {}
  const { countries, states, cities, updateAvailableStates, updateAvailableCities } = useAddressLocation(addToast, country, state)
  const schema = useMemo(() => pickupSchema(states, cities), [states, cities])

  const [zipCodeData, onZipCodeUpdate] = useZipCode(addToast)
  const [{ latLong }, onGeoCodeUpdate] = useGeoCode(addToast)

  const {
    form,
    errors,
    setForm,
    onSubmit,
    utils: { onInputBlur, onInputChange, addItemOnArray, removeItemArray }
  } = useForm(pickup, schema)

  const onValidationError = useCallback(() => addToast('Preencha corretamente o formulário'), [addToast])
  const handleSubmit = useMemo(() => onSubmit(onSuccess, onValidationError), [onSubmit, onSuccess, onValidationError])
  const onFormError = useCallback((message) => addToast(message), [addToast])

  const onZipCodeBlur = () => {
    onZipCodeUpdate(form.addressInfo?.zipCode)
    onInputBlur('addressInfo.zipCode')()
  }

  const getLatLng = useCallback(() => {
    const { zipCode, address, addressDetail, district } = form?.addressInfo || {}

    const countryName = getLocalityName(countries, form?.addressInfo.country)
    const stateName = getLocalityName(states, form?.addressInfo.state)
    const cityName = getLocalityName(cities, form?.addressInfo.city)
    const addressParam = `${zipCode} ${address} ${addressDetail} ${district} ${countryName} ${stateName} ${cityName}`

    onGeoCodeUpdate(addressParam)
  }, [form, countries, country, cities, states, state, city])

  const onCountryChange = useCallback(
    (countryValue) => {
      const country = countries.find((country) => Object.values(country).includes(countryValue))
      if (country) {
        updateAvailableStates(country.value)
        onInputChange('addressInfo.country')(country.value)
      } else {
        updateAvailableStates(null)
        onInputChange('addressInfo.country')('')
        onInputChange('addressInfo.state')('')
        onInputChange('addressInfo.city')('')
      }
    },
    [countries, updateAvailableStates, onInputChange]
  )

  const onStateChange = useCallback(
    (stateValue) => {
      const state = states.find((state) => Object.values(state).includes(stateValue))
      if (state) {
        updateAvailableCities(form.addressInfo?.country, state.value)
        onInputChange('addressInfo.state')(state.value)
      } else {
        updateAvailableCities(null)
        onInputChange('addressInfo.state')('')
        onInputChange('addressInfo.city')('')
      }
    },
    [states, updateAvailableCities, form.addressInfo?.country, onInputChange]
  )

  const onCityChange = useCallback(
    (cityValue) => {
      const city = cities.find((city) => Object.values(city).includes(cityValue))
      if (city) {
        onInputChange('addressInfo.city')(city.value)
      } else {
        onInputChange('addressInfo.city')('')
      }
    },
    [cities, onInputChange]
  )
  const updateAddress = useCallback(() => {
    if (Object.keys(zipCodeData).length) {
      setForm((previousForm) => ({
        ...previousForm,
        addressInfo: {
          ...previousForm.addressInfo,
          ...(zipCodeData?.district && { district: zipCodeData.district }),
          ...(zipCodeData?.address && { address: zipCodeData.address }),
          state: zipCodeData.state,
          city: zipCodeData.city
        }
      }))
      onCountryChange('BR')
    }
  }, [setForm, onCountryChange, zipCodeData])

  const updateAddressLatLong = useCallback(() => {
    onInputChange('addressInfo.latLong')(latLong)
  }, [setForm, onCountryChange, zipCodeData])

  useEffect(updateAddress, [zipCodeData])
  useEffect(updateAddressLatLong, [latLong])

  const updateCountries = useCallback(() => {
    if (countries?.length) {
      onCountryChange(form.addressInfo?.country)
    }
  }, [countries, onCountryChange, form.addressInfo?.country])

  useEffect(updateCountries, [countries])

  const updateStates = useCallback(() => {
    if (states?.length) {
      onStateChange(form.addressInfo?.state)
    }
  }, [states, onStateChange, form.addressInfo?.state])

  useEffect(updateStates, [states])

  const updateCities = useCallback(() => {
    if (cities?.length) {
      onCityChange(form.addressInfo?.city)
    }
  }, [cities, onCityChange, form.addressInfo?.city])

  useEffect(updateCities, [cities])

  return {
    form,
    errors,
    countries,
    states,
    cities,
    fetchCoordinates: getLatLng,
    onError: onFormError,
    onZipCodeBlur,
    addItemOnArray,
    removeItemArray,
    onStateChange,
    onCountryChange,
    onInputBlur,
    onInputChange,
    onSubmit: handleSubmit
  }
}
