/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { Column, Input, InputFormGroup, Row, Upload, UploadFootnote, useToast } from '@bonitour/components'
import { relativeStyle } from './CombinedExperienceForm.styles'
import { marginTop } from 'assets/styles/global'
import { FieldLabel, MultiLanguageInput } from 'components/MultiLanguageInput/MultiLanguageInput'

export const CombinedExperienceBaseForm = ({
  register = identity,
  errors = {},
  watch = identity,
  setValue = identity,
  isLoading = false
  // toggleIsEnabledExperience = identity,
  // isEnabledExperience = true
}) => {
  const files = watch('files')
  const { add: addToast } = useToast()

  return <div css={relativeStyle}>
    {/* TODO readd enable button later when experiences lit alows company to find its own disabled experiences */}
    {/* <div css={floatContainerStyle}>
      <ToggleInputGroup checked={isEnabledExperience} onClick={toggleIsEnabledExperience} disabled={isLoading}>
          Experiência ativada
      </ToggleInputGroup>
    </div> */}
    <Row css={marginTop(5)}>
      <Column phone={12} desktop={6}>
        <InputFormGroup label={<FieldLabel label='Título' isRequired />} errorMessage={errors?.title?.message}>
          <Input
            disabled={isLoading}
            onChangeWholeEvent
            {...register('title')}
          />
        </InputFormGroup>
      </Column>
      <Column phone={12} desktop={6}>
        <InputFormGroup label={<FieldLabel label='Subtítulo' isRequired />} errorMessage={errors?.subtitle?.message}>
          <Input
            disabled={isLoading}
            onChangeWholeEvent
            {...register('subtitle')}
          />
        </InputFormGroup>
      </Column>
    </Row>
    <Row>
      <Column phone={12} desktop={12}>
        <InputFormGroup label={<FieldLabel label='Imagem' />} errorMessage={errors?.description?.message}>
          <Upload
            files={files}
            onChange={(f) => setValue('files', f)}
            onError={addToast}
            limitMbSize={1}
            emptyMessage='Adicione uma foto com no máximo 1Mb'
            accept='image/png, image/jpeg, image/webp'
          />
          <UploadFootnote
            typeList={['.jpg', '.png', '.webp']}
          />
        </InputFormGroup>
      </Column>
    </Row>
    <Row>
      <Column phone={12} desktop={12}>
        <MultiLanguageInput
          label='Descrição'
          register={register}
          errors={errors?.description}
          isLoading={isLoading}
          name='description'
          textArea
          watch={watch}
        />
      </Column>
    </Row>
  </div>
}
