/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */

import { AddLabel, Card, GhostButton, InputFormGroup, TrashIcon, flex } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useFieldArray } from 'react-hook-form'
import { arrayInputWrapperStyle, descriptionAreaStyle, feeByServiceCardStyle, feeByServiceWrapperStyle, selectStyle } from './CombinedExperienceForm.styles'
import { clickable } from 'assets/styles/global'
import { MultiLanguageInput, FieldLabel } from 'components/MultiLanguageInput/MultiLanguageInput'
import { useCallback, useEffect, useMemo } from 'react'
import { useActivity } from 'contexts/Activity'
import { SafeJSONParse } from 'utils/object'
import { FEE_TYPES } from 'constants/fees'
import { ActivityCard } from 'containers/Activity/ActivityCard'
import { warningTabStyle } from 'components/MultiLanguageInput/MultiLanguageInput.styles'

export const CombinedExperienceFormFeeMap = ({
  register,
  errors,
  isLoading,
  control,
  selectedServices,
  setValue,
  watch
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'feeMap'
  })

  const appendEmptyFee = useCallback(() => {
    append({
      label: {},
      description: {},
      services: {}
    })
  }, [append])

  const {
    loading: isLoadingExperienceList,
    companyActivities = [],
    partnerActivities = []
  } = useActivity()

  const selectedServicesWithDetails = useMemo(
    () => {
      if (isLoadingExperienceList) {
        return []
      }
      const experienceList = companyActivities?.concat(partnerActivities)
      return selectedServices?.map((service) => {
        const { name, companyName, image, description } = experienceList?.find((exp) => exp?.id === service?.id) || {}
        const feeTypes = SafeJSONParse(description, null)?.limber_data?.categorias?.map(
          ({ nomeCategoria }) => nomeCategoria
        ) || Object.values(FEE_TYPES)
        const feeTypesOptions = feeTypes.map((feeType) => ({
          value: feeType,
          label: feeType
        }))
        return {
          ...service,
          name,
          companyName,
          image,
          feeTypesOptions
        }
      })
    },
    [selectedServices, companyActivities, partnerActivities, isLoadingExperienceList]
  )

  useEffect(() => {
    if (!isLoadingExperienceList) {
      fields?.forEach((field, index) => {
        const { services } = field
        // remove deleted services from feeMap
        Object.keys(services || []).forEach((serviceId) => {
          if (!selectedServices.find(({ id }) => id === serviceId)) {
            setValue(`feeMap[${index}].services[${serviceId}]`, undefined)
          }
        })
        // add new services to feeMap
        selectedServices.forEach(({ id }) => {
          if (!services[id]) {
            setValue(`feeMap[${index}].services[${id}]`, '')
          }
        })
      })
    }
  }, [fields, isLoadingExperienceList, selectedServices, setValue])

  return (
    <InputFormGroup
      label={<FieldLabel label='Mapa de tarifas' customCss={[!fields?.length && warningTabStyle]} />}
      errorMessage={errors?.title?.feeMap}
    >
      <div css={arrayInputWrapperStyle}>
        {fields?.map((field, index) => (
          <Card key={field.id}>
            <MultiLanguageInput
              label="Nome"
              isRequired
              register={register}
              name={`feeMap[${index}].label`}
              errors={errors?.feeMap?.[index]?.label}
              isLoading={isLoading}
              watch={watch}
            />
            <MultiLanguageInput
              label="Descrição"
              register={register}
              name={`feeMap[${index}].description`}
              errors={errors?.feeMap?.[index]?.description}
              isLoading={isLoading}
              textArea
              customInputStyle={[descriptionAreaStyle]}
              watch={watch}
            />
            <InputFormGroup label={<FieldLabel label='Tarifário a ser aplicado' isRequired />}>
              <div css={feeByServiceWrapperStyle}>
                {selectedServicesWithDetails?.map((service) => (
                  <Card key={service?.id} customCss={[feeByServiceCardStyle]}>
                    <InputFormGroup
                      errorMessage={errors?.feeMap?.[index]?.services?.[service.id]?.message}
                      label={
                        <ActivityCard
                          activity={service}
                          hideActionLabel
                          hasTitleMaxWidth
                          disableLink
                          disableOnClick
                          hideTypeLabel
                        />
                      }
                    >
                      <select {...register(`feeMap[${index}].services[${service.id}]`)} defaultValue="" css={selectStyle}>
                        <option value="">Selecione uma tarifa</option>
                        {service?.feeTypesOptions?.map(({ value, label }) => (
                          <option key={value} value={value}>{label}</option>
                        ))}
                      </select>
                    </InputFormGroup>
                  </Card>
                ))}
              </div>
            </InputFormGroup>
            <GhostButton
              type="button"
              disabled={isLoading}
              onClick={() => remove(index)}
              className="remove_fee__btn"
            >
              Remover tarifa
              <TrashIcon css={[clickable]} onClick={() => remove(index)} />
            </GhostButton>
          </Card>
        ))}
      </div>
      <AddLabel css={[flex]} onClick={appendEmptyFee}>
        Nova tarifa
      </AddLabel>
    </InputFormGroup>
  )
}
