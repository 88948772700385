import { PickUpAddressModel } from 'core/models/PickUpAddress'
import { PickUpAddressParser } from 'core/parsers/pickUpAddress'
import { orbCore } from 'services/Orb/Service'

const { pickUpAddressDomain } = orbCore

export const PickUpAddressesService = {
  async list ({ name = '', serviceId = '', pagination = { page: 1, perPage: 10 } }) {
    return await pickUpAddressDomain.list(PickUpAddressModel.listPayload({ name, serviceId, pagination })).then(PickUpAddressParser.list)
  },
  async create (data) {
    return await pickUpAddressDomain.create(PickUpAddressModel.create(data))
  },
  async delete (groupId) {
    return await pickUpAddressDomain.delete(groupId)
  }
}
