import { BREAK_POINTS, colors, flexHorizontallyCentered } from '@bonitour/components'
import { css } from '@emotion/core'

export const latLngContainer = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .button {
        ${flexHorizontallyCentered};
        white-space: nowrap;
        padding: 7px 10px;
        background-color: ${colors.primaryVariant};
        font-size: 14px;
        font-weight: 400;
        color: ${colors.white};
        border-radius: 5px;
        cursor: pointer;
        height: 20px;
        padding: 5px 10px;
    }

    .button.disabled {
        background-color: ${colors.gray6};
    }

    @media (max-width: ${BREAK_POINTS.smallPhone}){
        flex-direction: column;
    }
`
