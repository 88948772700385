/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Accordion, CollapsableSection, HeaderPage, LoadingAnimation, LoadingContainer } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { TransportAbout } from './TransportAbout'
import { EditTransportInfo } from './EditTransportInfo'
import { TransportComplementaryInfo } from './TransportComplementaryInfo'
import { useQuery } from 'hooks/useQuery'
import { useRouteByType } from 'hooks/useRouteByType'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { TransportService } from 'services/Transport/Service'
import { ActivityCategoryService } from 'services/Activity/ActivityCategory/Service'
import { useActivity } from 'contexts/Activity'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { ListActivityFee, ListActivityVacancy } from 'app'
import { useIsLockedService } from 'hooks/domains/useIsLockedService'
import { ListServicePickUpAddresses } from 'app/ListServicePickUpAddresses/ListServicePickUpAddresses'
import { useFeatureFlag } from 'contexts/Feature'

export const EditTransport = () => {
  const { push } = useHistory()
  const { search } = useLocation()
  const { id: serviceId } = useActivity()
  const [{ accordion = 1 }] = useQuery()
  const [service, setService] = useState({})

  useRouteByType(`${serviceId}/edit`)

  const updateService = useCallback(() => TransportService.get(serviceId).then(setService), [serviceId])
  const setIndicatorActive = accordionValue => push({ search: `?accordion=${accordionValue}` })

  useEffect(() => {
    updateService()
  }, [updateService])

  const backToDashboard = () => push(`/activity/${serviceId}/dashboard`)
  const onBackStep = () => setIndicatorActive(Number(accordion) - 1)
  const onNextStep = () => setIndicatorActive(Number(accordion) + 1)

  const [categories, setCategories] = useState([])

  useEffect(() => {
    ActivityCategoryService.list().then(setCategories)
  }, [])

  const categoryTitle = useMemo(() => {
    const categoryId = service?.transportAbout?.transportAbout?.category
    return categories.find(category => category.id === categoryId)?.title
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service?.transportAbout?.transportAbout?.category, categories])

  useEffect(() => {
    if ((categoryTitle && categoryTitle !== 'Transporte')) {
      push(`/activity/${serviceId}/edit${search}`)
    }
  }, [categoryTitle, push, search, serviceId])

  const allowedCategories = useMemo(() => (
    categories.filter(category => category.title === 'Transporte')
  ), [categories])

  const {
    lockedData,
    refetchGetIsLockedService
  } = useIsLockedService()

  const [isPickupPlacesEnabled, _, isFlagReady, isFlagTimedOut] = useFeatureFlag('orb-transport-pickup-selector')

  if (!isFlagReady && !isFlagTimedOut) {
    return (
      <LoadingContainer>
        <LoadingAnimation/>
      </LoadingContainer>
    )
  }

  return (
    <>
      <HeaderPage onBack={backToDashboard} title='Rota de transporte' />
      <Accordion value={Number(accordion)} onClick={setIndicatorActive}>
        <CollapsableSection
          indicator={1}
          title='Informações básicas'
          topics='Registro / Contato / Endereço de origem / Endereço de destino'
        >
          <EditTransportInfo
            transportBase={service.transportBase}
            onTransportUpdated={updateService}
            onNextStep={onNextStep}
          />
        </CollapsableSection>

        <CollapsableSection
          indicator={2}
          title='Informações técnicas'
          topics='Tempo de duração / Infraestrutura / Nível de dificuldade'
        >
          <TransportAbout
            transportId={serviceId}
            categories={allowedCategories}
            transportAbout={service.transportAbout}
            onTransportUpdated={updateService}
            onBackStep={onBackStep}
            onNextStep={onNextStep}
          />
        </CollapsableSection>

        <CollapsableSection
          indicator={3}
          title='Informações Complementares'
          topics='Descrição / Observações / O que levar / Incluso no transporte / Pagamentos aceitos'
          tooltip='Essas informações serão usadas para informar os viajantes sobre o transpote em plataformas de vendas'
        >
          <TransportComplementaryInfo
            transportId={serviceId}
            transportComplementaryInfo={service.transportComplementaryInfo}
            onTransportUpdated={updateService}
            onBackStep={onBackStep}
            onNextStep={onNextStep}
          />
        </CollapsableSection>

        <CollapsableSection indicator={4} title='Vagas' topics='Limite de vagas / Horários'>
          <ListActivityVacancy
            onBackStep={onBackStep}
            onNextStep={onNextStep}
            isTransport
            isActivityLocked={lockedData?.isLocked}
            onActionCallback={refetchGetIsLockedService}
          />
        </CollapsableSection>

        <CollapsableSection
          indicator={5}
          title='Tarifário'
          topics='Tarifário padrão / Tipificação de PAX / Tarifário por demanda'
        >
          <ListActivityFee
            onBackStep={onBackStep}
            onNextStep={isPickupPlacesEnabled ? onNextStep : backToDashboard}
            isTransport
          />
        </CollapsableSection>

        {isPickupPlacesEnabled
          ? (
            <CollapsableSection
              indicator={6}
              title='Locais de embarque'
              topics='Defina locais de embarque'
            >
              <ListServicePickUpAddresses
                serviceId={serviceId}
              />
            </CollapsableSection>
          )
          : <></>}

      </Accordion>
    </>
  )
}
