/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { formatMoney, identity } from '@bonitour/common-functions'
import { BREAK_POINTS, Button, Card, colors, Dialog, flexColumn, GhostPrimaryButton } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { COMBINED_EXPERIENCE_TYPE, OFFLINE_EXPERIENCE_TYPE } from 'constants/activityTypes'
import { OfflineExperiencesTicketForm } from 'containers/OfflineExperiencesTicketForm/OfflineExperiencesTicketForm'
import { ExperienceSelectorModal } from 'containers/Reservations/ReservationList/ExperienceSelectorModal'
import { useQuery } from 'hooks/useQuery'
import { useState, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const summary = css`
  background-color: #F4F4F4;
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: 0;
  z-index: 1;
  box-shadow: 0px -3px 6px #00000010;

  @media (max-width: ${BREAK_POINTS.bigPhone}){
    display: none;
  }
`

const summaryContent = css`
  position: relative;
  max-width: 1268px;
  padding: 15px;
  display: flex;
  margin: auto;
`

const amountDueCard = css`
  min-width: 150px;
  display: inline-flex;
  margin-right: 20px;
  padding: 20px;
`

const amountDueLabel = css`
  font-size: 12px;
  color: ${colors.gray5};
  margin-bottom: 5px;
`

const amountDueStyle = css`
  font-size: 24px;
  color: ${colors.gray1};
  font-weight: bold;
`

const continueButton = css`
  position: relative;
  padding: 10px 20px;
  display: inline-block;
  margin: auto;
  margin-right: 40px;
`

const buttonsArea = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
`

const dialogContainer = css`
  max-width: 800px;
`

const newReservationCss = css`
  display: inline-block;
  width: auto;
`

export const fiscalDutyDialog = css`
  p {
    margin-bottom: 10px;
  }
  button {
    margin-right: 20px;
  }
`

export const TicketSummary = ({ total = 0, onActivitySelect = identity, reservation, onTicketUpdate = identity }) => {
  const [openedOfflineExperienceId, setOpenedOfflineExperienceId] = useState(null)
  const [fiscalDutyBlocked, setFiscalDutyBlocked] = useState(false)
  const closeFiscalDutyDialog = useCallback(() => setFiscalDutyBlocked(false), [])
  const hasFiscalDuty = useMemo(() => Boolean(reservation?.fiscalDuty), [reservation?.fiscalDuty])
  const handleOpenedSelector = useCallback(() => {
    if (hasFiscalDuty) {
      setFiscalDutyBlocked(true)
    }
  }, [hasFiscalDuty])

  const onCloseOfflineExperience = useCallback(() => {
    setOpenedOfflineExperienceId(null)
  }, [])

  const [_queriesParam, { changeQueriesParam }] = useQuery()

  const onExperienceSelected = useCallback((id, type) => {
    switch (type) {
    case OFFLINE_EXPERIENCE_TYPE:
      setOpenedOfflineExperienceId(id)
      break
    case COMBINED_EXPERIENCE_TYPE:
      changeQueriesParam({
        isOpenedBookingMapModal: 'true',
        activityId: id
      })
    // eslint-disable-next-line no-fallthrough
    default:
      onActivitySelect(id)
    }
  }, [changeQueriesParam, onActivitySelect])

  const history = useHistory()
  const onNewReservation = useCallback(
    (email) => (id) => id && history.push(`/experience/${id}/bookings?email=${email}`),
    [history]
  )

  return (
    <>
      <div css={summary}>
        <div css={summaryContent}>
          <Card css={amountDueCard}>
            <div css={flexColumn}>
              <p css={amountDueLabel}>Valor total</p>
              <span css={amountDueStyle}>{formatMoney(total || 0)}</span>
            </div>
          </Card>
          <div css={buttonsArea}>
            {!fiscalDutyBlocked && (
              <ExperienceSelectorModal onExperienceSelected={onExperienceSelected} onClick={handleOpenedSelector}>
                <GhostPrimaryButton css={continueButton}>
                  Continuar reservando
                </GhostPrimaryButton>
              </ExperienceSelectorModal>
            )}
          </div>
        </div>
      </div>

      <Dialog
        customContainercss={[fiscalDutyDialog]}
        title='Reserva com NF emitida!'
        isVisible={fiscalDutyBlocked}
        onClose={closeFiscalDutyDialog}
      >
        <p>
          Não é possível adicionar novos ingressos a esta reserva pois ela possui uma nota fiscal emitida.
        </p>
        <p>
          Para reservar novos ingressos, é necessário criar uma nova reserva.
        </p>
        <ExperienceSelectorModal onExperienceSelected={onNewReservation(reservation?.info?.email)} customCss={[newReservationCss]}>
          <Button>Criar nova reserva</Button>
        </ExperienceSelectorModal>
        <GhostPrimaryButton onClick={closeFiscalDutyDialog}>Cancelar</GhostPrimaryButton>
      </Dialog>
      <Dialog customContainercss={[dialogContainer]} title='Adicionar experiência offline' isVisible={openedOfflineExperienceId} onClose={onCloseOfflineExperience}>
        <OfflineExperiencesTicketForm
          experienceId={openedOfflineExperienceId}
          reservationId={reservation?.id}
          reservationInfo={reservation?.info}
          onClose={onCloseOfflineExperience}
          onTicketUpdate={onTicketUpdate}
        />
      </Dialog>
    </>
  )
}
